import {getToken} from "../Utils/Commons";
import axios from "axios";

class FournisseurServiceTelRepository {

    getFournisseurs = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let rapport = null;
        const token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/FournisseurServiceTel.php?jwt=${token}&mode=getFournisseurs`,
            { headers }
        ).then(response => {
            rapport = JSON.parse(response.data.fournisseurs);
        }).catch(error => {
            // console.log(error);
        });

        return rapport;
    };

}

export default FournisseurServiceTelRepository