import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPhoneAlt} from "@fortawesome/free-solid-svg-icons";
import styled from '@emotion/styled';
import SupportTimeSlotService from "../services/SupportTimeSlotService";
import IconButton from '@mui/material/IconButton';
import {getUserColor, getUserSecondaryColor} from '../Utils/Commons'
import Snackbar from '@mui/material/Snackbar';
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Alert} from "@mui/lab";
import specialisationType from "../enums/specialisationType";
import Toast from "./ui/Toast";

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const TestCallButton = ({currentSpecialisation}) => {
    const primaryColor = getUserColor();
    const { t, i18n } = useTranslation();
    const [toastState, setToastState] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        message: t('calendar.testCallToast'),
        severity: 'success',
    });


    useEffect(() => {
        let message = t('calendar.testCallToast');
        const specialisation = currentSpecialisation === specialisationType.PLC ? "PLC": "SCADA";

        message = message.replace('%type%', specialisation);

        setToastState({...toastState, message: message});
    }, [currentSpecialisation])

    const handleClick = async () => {
        const supportTimeSlotService = new SupportTimeSlotService();
        await supportTimeSlotService.makeTestCall(currentSpecialisation);

        setToastState({...toastState, open: true});
    }

    return (
        <Wrapper>
            <IconButton onClick={() => handleClick()} primaryColor={primaryColor}>
                <FontAwesomeIcon icon={faPhoneAlt} size="sm" color={primaryColor}/>
            </IconButton>
            <Toast toastState={toastState} setToastState={setToastState} />
        </Wrapper>
    )
}

export default TestCallButton;