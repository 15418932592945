import LogTestConnectionRepository from "../repositories/LogTestConnectionRepository";

class LogTestConnectionService {

    repo = new LogTestConnectionRepository();

    getAllLogs = async () => {
        return await this.repo.getAllLogs();
    };

    getAllHistoryLogs = async (arrayFilter) => {
        return await this.repo.getAllHistoryLogs(arrayFilter);
    };

    saveLog = async (log) => {
        return await this.repo.saveLog(log);
    };

    deleteLog = async (log) => {
        return await this.repo.deleteLog(log);
    };

}

export default LogTestConnectionService;