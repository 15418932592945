import { getToken } from "../Utils/Commons";
import axios from "axios";


class FactoryRepository {
    getFactories = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var factories = null;
        var token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Factory.php?jwt=${token}&mode=factories`,
            { headers }
        )
            .then(response => {
                factories = response.data.factories;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return factories;
    }

    getFactoriesFilter = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var factories = null;
        var token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Factory.php?jwt=${token}&mode=factoriesFilter`,
            { headers }
        )
            .then(response => {
                factories = response.data.factories;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return factories;
    }

    getFactory = async (factoryId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var factory = null;
        var token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Factory.php?jwt=${token}&factoryId=${factoryId}&mode=factory`,
            { headers }
        )
            .then(response => {
                factory = response.data.factory;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return factory;
    }

    getPotentialDirectors = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let potentialDirectors = null;
        const token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Factory.php?jwt=${token}&mode=getPotentialDirectors`,
            { headers }
        )
            .then(response => {
                potentialDirectors = response.data.clients;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return potentialDirectors;
    }

    saveFactory = async (factory) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var res = null;
        var token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Factory.php`,
            { headers, jwt: token, factory: JSON.stringify(factory) }
        )
            .then(response => {
                res = {
                    success: true,
                    factoryId: response.data.factoryId
                }
            }).catch(error => {
                res = {
                    success: false,
                    message: error.response.data.message
                }
            });

        return res;
    }
}

export default FactoryRepository;