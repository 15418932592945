import { getToken } from "../Utils/Commons";
import axios from "axios";

class RapportInterventionRepository {

    getRapportByConversationId = async (conversationId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let rapport = null;
        const token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/RapportIntervention.php?jwt=${token}&conversationId=${conversationId}&mode=getRapportByConversationId`,
            { headers }
        ).then(response => {
            rapport = JSON.parse(response.data.rapportIntervention);
        }).catch(error => {
            // console.log(error);
        });

        return rapport;
    };

    getAllRapports = async (arrayFilter) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let rapport = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/RapportIntervention.php`,
            { jwt: token, mode: "getAllRapports", arrayFilter: arrayFilter},
            { headers }
        ).then(response => {
            rapport = JSON.parse(response.data.rapportIntervention);
        }).catch(error => {
            // console.log(error);
        });

        return rapport;
    };

    saveRapport = async (rapport) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let res = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/RapportIntervention.php`,
            { headers, jwt: token, rapport: JSON.stringify(rapport), mode: "saveRapport" }
        ).then(() => {
            res = {
                success: true
            }
        }).catch(error => {
            res = {
                success: false,
                message: error.response.data.message
            }
        });

        return res;
    };

    getNextReportNumber = async (conversationId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let rapport = null;
        const token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/RapportIntervention.php?jwt=${token}&conversationId=${conversationId}&mode=getNextReportNumber`,
            { headers }
        ).then(response => {
            rapport = JSON.parse(response.data.rapportIntervention);
        }).catch(error => {
            // console.log(error);
        });

        return rapport;
    };

    sendRapportEmail = async (pdfBlob, codeProjet, conversationId) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };
        let res = null;
        const token = getToken();

        const reader = new FileReader();
        reader.readAsDataURL(pdfBlob);
        reader.onloadend = async function () {

            await axios.post(
                `${process.env.REACT_APP_API_URL}api/RapportIntervention.php`,
                {headers, jwt: token, rapport: reader.result, codeProjet: codeProjet, conversationId: conversationId, mode: "sendEmail"}
            ).then(() => {
                res = {
                    success: true
                }
            }).catch(error => {
                res = {
                    success: false,
                    message: error.response.data.message
                }
            });
            return res;
        }
    };
}

export default RapportInterventionRepository