import ConversationRepository from "../repositories/ConversationRepository";

class ConversationService {

    repo = new ConversationRepository();

    getConversation = async (conversationId) => {
        if (!conversationId) return;

        const conversation = await this.repo.getConversation(conversationId);

        return JSON.parse(conversation);
    };

    getConversations = async (selectedSpecialisation) => {
        const conversationRepo = new ConversationRepository();
        const conversations = await conversationRepo.getConversations(selectedSpecialisation);

        if(!conversations) return [];

        return JSON.parse(conversations);
    };

    getConversationsHistory = async (arrayFilter) => {
        const conversationRepo = new ConversationRepository();
        const conversations = await conversationRepo.getConversationsHistory(arrayFilter);

        if(!conversations) return [];

        return JSON.parse(conversations);
    };

    getLatestConversation = async () => {
        const conversationRepo = new ConversationRepository();
        return await conversationRepo.getLatestConversation();
    }

    createNewConversation = async(userId, message, modifiedName, modifiedPhone, isSupportHebdo) => {
        if(!userId || !message) return;

        const conversationRepo = new ConversationRepository();
        return await conversationRepo.createNewConversation(userId, message, modifiedName, modifiedPhone, isSupportHebdo);
    }

    cancelAlert = async (conversationId) => {
        if (!conversationId) return;

        const conversationRepo = new ConversationRepository();
        return await conversationRepo.cancelAlert(conversationId);
    }

    deleteConversation = async (conversationId) => {
        if (!conversationId) return;
        await this.repo.deleteConversation(conversationId);
    }
}

export default ConversationService;