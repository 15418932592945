import React, {useState} from "react";
import styled from '@emotion/styled';
import {
    faCarSide,
    faPhoneAlt,
    faClipboard,
    faSignOutAlt,
    faCheckCircle,
    faCogs,
    faFileSignature,
    faBook, faFilePdf, faFileContract
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {useHistory, useParams} from "react-router-dom";

import ConversationStatusService from "../services/conversationStatusService";

import {removeUserSession, getToken, userHasPermission} from "../Utils/Commons";
import MenuOption from "./MenuOption";
import LanguageSelector from "./LanguageSelector";
import statusEnum from "../enums/statusEnum";
import ModalBackdrop from "./ModalBackdrop";

const MenuWrapper = styled.div(
    {
        position: "absolute",
        right: "0",
        top: "40px",
        margin: "2px",
        borderRadius: "3px",
        minWidth: "80px",
        padding: "10px",
        zIndex: "9",
        boxShadow: "rgba(0, 0, 0, 0.25) 3.95px 3.95px 5px"
    }, props => ({backgroundColor: props.color})
)

const LanguageSelectorWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
`

const VersionLabel = styled.div`
    position: absolute;
    bottom: 2px;
    left: 2px;
    font-size: 9px;
`

const NavbarMenuOptions = ({
                               backgroundColor,
                               setMenuVisibility,
                               setRefetchStatus,
                               setNoteVisibility,
                               rapportVisibility
                           }) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [conversationId] = useState(useParams().conversationId || 0);
    const version = process.env.REACT_APP_VERSION_APP;

    const handleUpdateStatus = async (status) => {
        const conversationStatusService = new ConversationStatusService();
        await conversationStatusService.updateConversationStatus(status, conversationId);
        setRefetchStatus(true);
        setMenuVisibility(false);
    }

    const handleNoteVisibility = () => {
        setNoteVisibility(true);
        setMenuVisibility(false);
    }

    const handleLogout = () => {
        setMenuVisibility(false);
        removeUserSession();
        history.push('/');
        sessionStorage.clear();
    }

    const handleSettings = () => {
        history.push('/settings');
    }

    const handleResetPassword = () => {
        history.push('/resetPassword');
    }

    const handleTestHistory = () => {
        history.push('/testConnectionHistory');
    }

    const handleRIHistory = () => {
        history.push('/interventionReportHistory');
    }

    const isChatPage = () => {
        return window.location.pathname.includes("/chat/");
    };

    const handleCheckPdf = () => {
        window.open(`${process.env.REACT_APP_API_URL}api/ExportChatPdf.php?conversationId=${conversationId}&jwt=${getToken()}`);
    };

    const handleAgreement = () => {
        history.push('/agreement');
    };

    const CallOptions = () => {
        if (userHasPermission("canMakeCalls")) {
            return;
        }
        return (
            conversationId !== 0 && isChatPage() && <>
                {userHasPermission("canAnswerCalls") &&
                    <>
                        <MenuOption icon={faPhoneAlt} label={t('navbar.status.called')}
                                    changeStatus={() => handleUpdateStatus(statusEnum.CALLED)}/>
                        <MenuOption icon={faCarSide} label={t('navbar.status.moved')}
                                    changeStatus={() => handleUpdateStatus(statusEnum.MOVED)}/>
                        <MenuOption icon={faCheckCircle} label={t('navbar.status.resolved')}
                                    changeStatus={() => handleUpdateStatus(statusEnum.RESOLVED)}/>
                        {rapportVisibility &&
                            <MenuOption icon={faFileSignature} label={t('navbar.menuOptions.rapportIntervention')}
                                        changeStatus={() => history.push(`/rapportIntervention/${conversationId}`)}/>
                        }

                    </>
                }
                <MenuOption icon={faFilePdf} label={"Export"} changeStatus={() => handleCheckPdf()}/>
                <MenuOption icon={faClipboard} label={t('navbar.menuOptions.note')}
                            changeStatus={() => handleNoteVisibility()} last/>
                <hr style={{border: "1px solid black"}}/>
            </>
        )
    }

    const AdminOptions = () => {
        if (userHasPermission("canMakeCalls")) {
            return;
        }
        return (
            <>
                {userHasPermission("canSeeHistoTest") &&
                    <MenuOption icon={faBook} label={t('navbar.menuOptions.testHistoric')}
                                changeStatus={handleTestHistory}/>
                }
                {userHasPermission("canSeeHistoIntervention") &&
                    <MenuOption icon={faBook} label={t('navbar.menuOptions.rapportInterventionHistoric')}
                                changeStatus={handleRIHistory}/>
                }

                {(userHasPermission("canSeeHistoTest") && userHasPermission("canSeeHistoIntervention")) &&
                    <hr style={{border: "1px solid black"}}/>
                }

                {userHasPermission("canSeeConfig") &&
                    <MenuOption icon={faCogs} label={t('navbar.settings')} changeStatus={handleSettings}/>
                }
            </>
        )
    };

    const menuOptions = () => {
        return (
            <>
                {CallOptions()}
                {AdminOptions()}
                {userHasPermission("canSeeAgreement") &&
                    <MenuOption icon={faFileContract} label={t('navbar.agreement')} changeStatus={handleAgreement}/>
                }
                <MenuOption icon={faCogs} label={t('navbar.resetPasword')} changeStatus={handleResetPassword}/>
                <MenuOption icon={faSignOutAlt} label={t('navbar.logout')} changeStatus={handleLogout}/>
                <LanguageSelectorWrapper>
                    <LanguageSelector size="14px"/>
                </LanguageSelectorWrapper>
                <VersionLabel>{version}</VersionLabel>
            </>
        )
    }

    return (
        <>
            <ModalBackdrop setStateValue={setMenuVisibility}/>
            <MenuWrapper color={backgroundColor}>
                {menuOptions()}
            </MenuWrapper>
        </>
    )
}

export default NavbarMenuOptions;