import axios from 'axios';
import { getToken, getUser } from '../Utils/Commons';

class ConversationRepository {

    res = {success: false, message: null};
    token = getToken();

    getConversation = async (conversationId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var conversation = null;
        var token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Conversation.php?conversationId=${conversationId}&jwt=${token}`,
            { headers }
        )
            .then(response => {
                conversation = response.data.conversation;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return conversation;
    }

    getConversations = async (selectedSpecialisation) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let conversations = null;
        const token = getToken();
        const user = getUser();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Conversations.php?jwt=${token}&loggedUserID=${user.id}&selectedSpecialisation=${selectedSpecialisation}`,
            { headers }
        )
            .then(response => {
                conversations = response.data.conversations;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return conversations;
    }

    getConversationsHistory = async (arrayFilter) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let conversations = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Conversations.php`,
            { jwt: token, mode: "getConversationsHistory", arrayFilter: arrayFilter},
            { headers }
        )
            .then(response => {
                conversations = response.data.conversations;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return conversations;
    }


    getLatestConversation = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var conversationData = null;
        var token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Conversations.php?jwt=${token}&mode=getLastConversation`,
            { headers }
        )
            .then(response => {
                conversationData = response.data;
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return conversationData;
    }

    createNewConversation = async (userId, message, modifiedName, modifiedPhone, isSupportHebdo) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var token = getToken();
        var conversationId = null;
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Conversation.php`,
            { jwt: token, userId, message, mode: 'newConversation', modifiedName, modifiedPhone, isSupportHebdo },
            { headers }
        )
            .then(response => {
                conversationId = response.data.conversationId
            }).catch(error => {
                // Handle error back to the service > login page
            });

        return conversationId;
    }

    cancelAlert = async (conversationId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        var token = getToken();
        var res = {
            success: false,
            message: null
        }
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Conversation.php`,
            { jwt: token, conversationId, mode: 'cancelAlert' },
            { headers }
        )
            .then(response => {
                res.success = true;
            }).catch(error => {
                res = {
                    success: false,
                    message: error.response.data.message
                }
            });

        return res;
    }

    deleteConversation = async (conversationId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Conversation.php`,
            { jwt: this.token, conversationId, mode: 'deleteConversation' },
            { headers }
        )
            .then(response => {
                this.res.success = true;
            }).catch(error => {
                this.res = {
                    success: false,
                    message: error.response.data.message
                }
            });

        return this.res;
    }
}

export default ConversationRepository;