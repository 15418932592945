import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

export const initializeFirebase = () => {
  initializeApp({
    apiKey: "AIzaSyA-x8nHVi8YK3RZWPKGbWzxhJ-2dpgKpYo",
    authDomain: "solu360-312de.firebaseapp.com",
    projectId: "solu360-312de",
    storageBucket: "solu360-312de.appspot.com",
    messagingSenderId: "519946611292",
    appId: "1:519946611292:web:33db54a5f362d886205499",
    measurementId: "G-LQ0V9KVM7C"
  });
}

export const getUserToken = async () => {
    var token = null;

    if(await isSupported()) {
      const messaging = getMessaging();

      await getToken(messaging, { vapidKey: 'BLRXfIcU_hvf4jJU5ntzLseOir6w1_75JtrMMoj_oP4tve1cZ3yd8ooBqtRZd8axs2Kg6469jiIc2wspQ74Argg' }).then((currentToken) => {
        if (currentToken) {
          token = currentToken;
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    }
    
    return token;
}