import styled from '@emotion/styled';

const Wrapper = styled.div`
  @media (min-width: 600px) {
    width: 60%;
  }
  @media (max-width: 600px) {
    width: 95%;
  }
  margin: auto;
`

const FormWrapper = ({children}) => {
  return (
    <Wrapper>
      {children}
    </Wrapper>
  )
}

export default FormWrapper;