import React, {useEffect, useState} from "react";
import Conversation from "../components/Conversation";
import ConversationService from "../services/ConversationService";
import styled from '@emotion/styled';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import DatePicker from 'react-date-picker'
import {useTranslation} from "react-i18next";
import FactoryService from "../services/FactoryService";
import ClientService from "../services/ClientService";
import { Autocomplete } from '@mui/material'
import TextField from "@mui/material/TextField";
import {isObject} from "@mui/x-data-grid/utils/utils";
import {solumationFactoryId} from "../global_variables";
import { saveDataInStorage, getValueFromStorage } from "../Utils/Commons";

const currentPageKeyFilter = "conversationListHistory.";

const Wrapper = styled.div`
  padding-top: 10px;
  overflow: auto;
  height: 95vh;
`

const VerticalCenterFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Filter = styled.div`
  margin: 5px;
  width: 300px;
  text-align: center;
`

// Initiate socket connection
const clientUrl = `${process.env.REACT_APP_WEBSOCKET_URL}?conversationPageRefreshUsers`;
let client = null;

const ConversationListHistoryPage = () => {
    const [conversations, setConversations] = useState([]);
    const [dateFrom, setDateFrom] = useState(getValueFromStorage(currentPageKeyFilter + "dateFrom"));
    const [dateTo, setDateTo] = useState(getValueFromStorage(currentPageKeyFilter + "dateTo"));

    const [techFilter, setTechFilter] = useState(getValueFromStorage(currentPageKeyFilter + "techFilter"));
    const [clientFilter, setClientFilter] = useState(getValueFromStorage(currentPageKeyFilter + "clientFilter"));
    const [factoryFilter, setFactoryFilter] = useState(getValueFromStorage(currentPageKeyFilter + "factoryFilter"));

    const [techniciens, setTechniciens] = useState([]);
    const [clients, setClients] = useState([]);
    const [factories, setFactories] = useState([]);
    const { t } = useTranslation();
    const [callShownAmount, setCallShownAmount] = useState(0);
    const [isCallDeleted, setIsCallDeleted] = useState(false);

    useEffect(() => {
        connect();
        return () => {
            client && client.close();
        }
    }, []);

    useEffect(() => {
        if (client != null) {
            client.onmessage = (message) => {
                const parsedContent = JSON.parse(message.data);

                if (parsedContent.type === "updateConversations") {
                    fetchData();
                }
            }
        }
    }, [client])

    useEffect(()=>{
        fetchData();
    },[dateFrom, dateTo, factoryFilter, clientFilter, techFilter])

    useEffect(() => {
        if (isCallDeleted) {
            setCallShownAmount(conversations.filter(c => c.deleted === 0).length)
            setIsCallDeleted(false);
        }
    }, [isCallDeleted]);

    const fetchData = async () => {
        const dateFromUtc = Date.UTC(dateFrom.getUTCFullYear(), dateFrom.getUTCMonth(), dateFrom.getUTCDate(), 0, 0, 0);
        const dateToUtc = Date.UTC(dateTo.getUTCFullYear(), dateTo.getUTCMonth(), dateTo.getUTCDate(), 23, 59, 59);

        const conversationService = new ConversationService();
        const conversationsData = await conversationService.getConversationsHistory([new Date(dateFromUtc), new Date(dateToUtc), factoryFilter, clientFilter, techFilter]);

        setConversations(conversationsData);
        setCallShownAmount(conversationsData.filter(c => c.deleted === 0).length)

        if (factories.length <= 0) {
            const factoryService = new FactoryService();
            const factoriesData = await factoryService.getFactoriesFilter();

            factoriesData.forEach(factory => {
                factory.label = factory.name;
            });
            setFactories(factoriesData);
        }

        if (clients.length <= 0 && techniciens.length <= 0){
            fetchClient();
        }
    };

    const fetchClient = async () => {

        const clientService = new ClientService();
        const clientsData = await clientService.getClientsFilter();

        clientsData.forEach(client => {
            client.label = client.name;
        });

        let techniciensData = clientsData.filter(client => client.factoryId === solumationFactoryId && client.roles !== undefined &&
            client.roles.find(role => role.permissions.find(perm => perm.permission === "canAnswerCalls")));

        setClients(clientsData);
        setTechniciens(techniciensData);
    }

    function connect() {
        client = new W3CWebSocket(clientUrl);
    }

    const renderOptions = (props: React.HTMLAttributes<HTMLElement>, option: Partial<any>) => {
        if(getFactoriesByName(factoryFilter).filter(factory => factory.id === option.factoryId).length > 0){
            return <li {...props} key={option.id}>{option.label}</li>
        }
    };

    const getFactoriesByName = (name) => {
        return factories.filter(factory => factory.name.toLowerCase().includes(name?.toLowerCase()));
    };

    return (
        <Wrapper>
            <div className={"filterContainer"}>
                <VerticalCenterFilters>
                    <Filter className={"inputFilter"} style={{zIndex: 2}}>
                        <label style={{marginRight: "5px", textAlign: "bottom"}}>{t('filters.dateFrom') + ":"}</label>
                        <DatePicker onChange={(value) => { saveDataInStorage(currentPageKeyFilter + "dateFrom", value.getTime()); setDateFrom(value) }}
                                    value={dateFrom} format={"y-MM-dd"} clearIcon={null} maxDate={dateTo}/>
                    </Filter>
                    <Filter className={"inputFilter"} style={{zIndex: 2}}>
                        <label style={{marginRight: "5px", textAlign: "bottom"}}>{t('filters.dateTo') + ":"}</label>
                        <DatePicker onChange={(value) => { saveDataInStorage(currentPageKeyFilter + "dateTo", value.getTime()); setDateTo(value) }}
                                    value={dateTo} format={"y-MM-dd"} clearIcon={null} minDate={dateFrom}/>
                    </Filter>

                    <Filter className={"inputFilter"}>
                        <Autocomplete className={"autoComplete"} freeSolo id="combo-box-factories" options={factories}
                                      renderInput={(params) => <TextField {...params} label={t('filters.factory')} />}
                                      value={factoryFilter}
                                      onInputChange={(event, newValue) => {
                                          const value = (isObject(newValue) ? newValue.name : newValue);

                                          saveDataInStorage(currentPageKeyFilter + "factoryFilter", value);
                                          setFactoryFilter(value)
                                          setClientFilter("");
                                      }}
                                      isOptionEqualToValue={(option, value) => option.name.toLowerCase().includes(value.toLowerCase())}
                        />
                    </Filter>
                    <Filter className={"inputFilter"}>
                        <Autocomplete className={"autoComplete"} freeSolo id="combo-box-clients" options={clients}
                                      renderInput={(params) => <TextField {...params} label={t('filters.client')} />}
                                      value={clientFilter}
                                      onInputChange={(event, newValue) => {
                                          const value = (isObject(newValue) ? newValue.name : newValue);

                                          saveDataInStorage(currentPageKeyFilter + "clientFilter", value);
                                          setClientFilter(value);
                                      }}
                                      isOptionEqualToValue={(option, value) => option.name.toLowerCase().includes(value.toLowerCase())}
                                      renderOption={renderOptions} disabled={factoryFilter === "" || factoryFilter === null}
                        />
                    </Filter>

                    <Filter className={"inputFilter"}>
                        <Autocomplete className={"autoComplete"} freeSolo id="combo-box-techniciens"
                                      options={techniciens}
                                      renderInput={(params) => <TextField {...params} label={t('filters.technician')}/>}
                                      value={techFilter}
                                      onInputChange={(event, newValue) => {
                                          const value = (isObject(newValue) ? newValue.name : newValue);

                                          saveDataInStorage(currentPageKeyFilter + "techFilter", value);
                                          setTechFilter(value);
                                      }}
                                      isOptionEqualToValue={(option, value) => option.name.toLowerCase().includes(value.toLowerCase())}
                        />
                    </Filter>

                    <Filter style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                        <div>{t('others.callShown')}: {callShownAmount}</div>
                    </Filter>
                </VerticalCenterFilters>
            </div>
            {conversations?.map((c, i) => (
                <Conversation conversation={c} key={i} setIsCallDeleted={setIsCallDeleted} />
            ))}
        </Wrapper>
    )
}

export default ConversationListHistoryPage;