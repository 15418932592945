const UseSendSocket = (client, userId, type, roomId, message = null, userData = null) => {
    if (client == null || !isSocketOpen(client)) return;

    client.send(JSON.stringify({
        type,
        roomId,
        userId,
        message,
        userData
    }));
}

function isSocketOpen(websocket){
    return websocket.readyState === websocket.OPEN;
}

export default UseSendSocket;