import UserRepository from "../repositories/UserRepository";

class UserService {
  getAnswerers = async () => {
    const repo = new UserRepository();
    const clients = await repo.getAnswerers();

    clients.value = JSON.parse(clients.value)

    return clients;
  }

  updateAnswerer = async (id, plcSupport, scadaSupport) => {
    if(!id) return;

    const repo = new UserRepository();
    const res = await repo.updateAnswerer(id, plcSupport, scadaSupport);

    return res;
  }
}

export default UserService;