import { useTranslation } from "react-i18next";
import styled from '@emotion/styled';

const LanguageChoice = styled.div(
  {
    width: "25px",
    height: "25px",
    background: "transparent",
    border: "none",
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  }, props => ({fontSize: props.size ?? "20px"})
)


const LanguageSelector = ({size}) => {
  const { i18n } = useTranslation();

  const renderLanguageChoice = () => {
    var langueageToShow = i18n.language === "en" ? "fr" : "en";
    return <LanguageChoice size={size} onClick={() => i18n.changeLanguage(langueageToShow)}>{langueageToShow}</LanguageChoice>
  }

  return (
    <>
      {renderLanguageChoice()}
    </>
  )
}

export default LanguageSelector;