import { getToken, getUser } from "../Utils/Commons";
import axios from "axios";

class UserRepository {
  getAnswerers = async () => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var res = null;
    var token = getToken();
    await axios.get(
      `${process.env.REACT_APP_API_URL}api/User.php?jwt=${token}&mode=answerers`,
      { headers }
    )
    .then(response => {
      res = {
        success: true,
        value: response.data.answerers
      };
    }).catch(error => {
      res = {
        success: false
      };
    });

    return res;
  }

  updateAnswerer = async (id, plcSupport, scadaSupport) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var res = null;
    var token = getToken();
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/User.php`,
      { headers, jwt: token, mode: "updateAnswerer", id, plcSupport, scadaSupport }
    )
    .then(response => {
      res = {
        success: true
      };
    }).catch(error => {
      res = {
        success: false
      };
    });

    return res;
  }
}

export default UserRepository;