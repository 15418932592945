import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

const InputWrapper = styled(TextField)`
  width: ${props => props.type === 'number' ? '100px' : ''}
`

const Row = styled(Box)`
  padding: 3px;
`

const Input = ({onChange, placeholder, type, value, label, bindOn, fullWidth, autoCompleteOff, onKeyDown, multiline, rows, required}) => {
  function formatPhoneNumber(phoneNumberString) {
    if(type !== "phoneNumber") return;

    var cleaned = ('' + phoneNumberString.target.value).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      onChange('(' + match[1] + ') ' + match[2] + '-' + match[3], bindOn);
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }

    onChange(phoneNumberString.target.value, bindOn);
    return phoneNumberString.target.value;
  }

  return (
    <Row sx={{
      width: fullWidth ? '100%' : 'auto'
    }}>
      <FormControl fullWidth>
        <InputWrapper
          error={!value && required}
          variant="outlined" 
          helperText={label}
          label={placeholder} 
          value={value ?? ""} 
          type={type}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          onChange={(e) => onChange(e.target.value, bindOn, e)}
          onBlur={(v) => formatPhoneNumber(v)}
          inputProps={{
            autoComplete: autoCompleteOff ? 'new-password' : '',
            form: {
              autoComplete: 'on',
            },
            type: type,
            style: {
              minHeight: (rows > 0) ? `${rows * 23}px`: ''
            }
          }}
          multiline={multiline ?? false}
        />
      </FormControl>
    </Row>
  )
}

Input.defaultProps = {
  type: 'text',
  fullWidth: false
}

export default Input;