import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import AuthentificationService from '../services/authentificationService';
import {setUserColor, userHasPermission} from '../Utils/Commons';
import {useTranslation} from "react-i18next";
import LanguageSelector from '../components/LanguageSelector';
import UseValidateToken from '../hooks/useValidateToken';
import ConversationService from '../services/ConversationService';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import httpStatusCodes from "../enums/httpStatusCodes";
import { isIE, isEdge, isEdgeChromium } from "react-device-detect";
import axios from "axios";
import { Netmask } from 'netmask';


const Background = styled.div`
  height: 100vh;
  background: linear-gradient(217deg, rgba(170,136,255,0.5), rgba(255,0,0,0) 70.71%), linear-gradient(127deg, rgba(109,188,232,0.8), rgba(0,255,0,0) 70.71%), linear-gradient(336deg, rgba(150,230,179, 0.8), rgba(0,0,255,0) 70.71%);
`

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

const LanguageSelectorWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

const AppName = styled.div`
  margin: auto;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
`

const ButtonRow = styled.div`
  text-align: right;
`

const Error = styled.div`
  font-size: 12px;
  color: red;
  padding-top: 5px
`

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [isZscaler, setIsZscaler] = useState(false);
  const subnets = [
      '8.25.203.0/24',
      '64.74.126.64/26',
      '70.39.159.0/24',
      '72.52.96.0/26',
      '89.167.131.0/24',
      '104.129.192.0/20',
      '112.196.99.180/32',
      '136.226.0.0/16',
      '137.83.128.0/18',
      '147.161.128.0/17',
      '165.225.0.0/17',
      '165.225.192.0/18',
      '167.103.0.0/16',
      '170.85.0.0/16',
      '185.46.212.0/22',
      '199.168.148.0/22',
      '213.152.228.0/24',
      '216.52.207.64/26',
      '216.218.133.192/26',
      '2400:7aa0:1b11::/48',
      '2400:7aa0:1c11::/48',
      '2400:7aa0:1c12::/48',
      '2400:7aa0:1e11::/48',
      '2605:4300:1211::/48',
      '2605:4300:1212::/48',
      '2605:4300:1214::/48',
      '2605:4300:1216::/48',
      '2605:4300:1311::/48',
      '2605:4300:1312::/48',
      '2605:4300:1315::/48',
      '2605:4300:1411::/48',
      '2605:4300:1412::/48',
      '2605:4300:1413::/48',
      '2605:4300:1414::/48',
      '2605:4300:1418::/48',
      '2a03:eec0:1211::/48',
      '2a03:eec0:1212::/48',
      '2a03:eec0:1216::/48',
      '2a03:eec0:1311::/48',
      '2a03:eec0:1312::/48',
      '2a03:eec0:1317::/48',
      '2a03:eec0:1411::/48',
      '2a03:eec0:1414::/48',
      '2a03:eec0:1415::/48'
  ];

  useEffect(async () => {
    UseValidateToken().then((valid) => {
      if (valid) {
        if (!userHasPermission("canMakeCalls")) {
          redirectUser(null);
        } else if (userHasPermission("canMakeCalls")) {
          getLatestConversationId();
        }
      }
    });
      await getUserIpAddress();
  }, []);

  const verifyIpFromZscaler = (ipAddress) => {
      subnets.forEach((subnet) => {
          const block = new Netmask(subnet);
          if (block.contains(ipAddress)){
              setIsZscaler(true);
              return;
          }
      });
  }

  const getUserIpAddress = async () => {
      const headers = {
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      };

      await axios.get(
          `${process.env.REACT_APP_API_URL}api/Client.php?mode=getIpAddress`,
          {headers}
      )
          .then(response => {
              verifyIpFromZscaler(response.data.REMOTE_ADDR);
          }).catch(error => {
              // console.log(error.message);
              if (error.message.includes('Network Error')) {
                  setIsZscaler(true)
              } else {
                  // setError(error.message);
              }
          });
  }

  const getLatestConversationId = async () => {
    const conversationService = new ConversationService();
    const latestActiveConversationId =  await conversationService.getLatestConversation().lastConversationId;

    redirectUser(latestActiveConversationId);
  }

  const handleLogin = async () => {
    if (isIE || isEdge || isEdgeChromium || isZscaler) {
      return;
    }

    setError(null);

    if(!username || !password) {
      setError(t('login.errors.credentialsRequired'));
      return;
    }

    const authService = new AuthentificationService();
    const res = await authService.login(username, password);

    if (res === httpStatusCodes.FORBIDDEN) {
        setError(t('login.errors.disabledAccount'));
        return;
    } else if (res === httpStatusCodes.ERROR || res === httpStatusCodes.NOT_FOUND) {
        setError(t('login.errors.invalidCredentials'));
        return;
    }

    i18n.changeLanguage(res.user?.language)

    redirectUser(res.conversationId);
  }

  const redirectUser = (conversationId) => {
    const link = history.location.state?.from;
    if(userHasPermission("canUseAdminColors")) {
      setUserColor('#6DBCE8', '#9ecce6', '#023552');
      history.push(link !== undefined ? link : userHasPermission("canAnswerCalls") ? '/conversations' : '/settings');
    } else if (userHasPermission("canUseDirectorColors")) {
      setUserColor('#FFAC30', '#FFDEAD', '#FFFFFF');
      history.push(link !== undefined ? link : userHasPermission("canAnswerCalls") ? '/conversations' : '/historyList');
    } else if (userHasPermission("canUseRepondantColors")) {
      setUserColor('#AA87FD', '#D4C2FC', '#FFFFFF');
      history.push(link !== undefined ? link : '/conversations');
    } else {
      setUserColor('#97E6B3', '#E5FDE5', '#545454');
      history.push('/chat' + (conversationId ? '/' + conversationId : ''));
    }
  }

  const handleKeyDownSubmit = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleOnChange = (value, bindOn) => {
    bindOn(value);
  }

  return (
    <Background>
      <LanguageSelectorWrapper>
        <LanguageSelector />
      </LanguageSelectorWrapper>
      <Wrapper>
        <AppName>Solumation</AppName>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Input placeholder={t('login.inputs.username')} onChange={handleOnChange} bindOn={setUsername} value={username} onKeyDown={handleKeyDownSubmit} />
          <Input placeholder={t('login.inputs.password')} onChange={handleOnChange} bindOn={setPassword} value={password} type="password" autoComplete onKeyDown={handleKeyDownSubmit} />
        </div>
        <ButtonRow>
          <Button action={handleLogin} textContent={t('login.buttons.connection')} />
        </ButtonRow>
        {(isIE || isEdge || isEdgeChromium || isZscaler) ?
          <Error>
            <div style={{textAlign: "center"}}>*** {t('login.errors.edge.start')} <span style={{fontWeight: "bold"}}>
                {isZscaler ? t('login.errors.network') : t('login.errors.edge.browser')}
            </span> ***</div>
            <div>{t('login.errors.edge.end')}</div>
          </Error> : ''
        }
        <Error>{error}</Error>
      </Wrapper>
    </Background>
  );
}

export default Login;

