import styled from '@emotion/styled';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import React from "react";

const Wrapper = styled.div(
  {
    display: 'flex',
    height: '40px',
    position: 'absolute',
    bottom: '0',
    width: '100%'
  }, props => ({backgroundColor: props.color})
)

const Input = styled.input`
  flex: 10;
  border: none;
  outline: none;
  background: none;
  padding: 10px;
`

const SubmitButton = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 60px;
`

const ChatMessageInput = ({ chatMessage, handleChatMesageChange, handleKeyDownSubmit, handleSubmit, secondaryColor }) => {
  const { t } = useTranslation();
  return (
      <Wrapper color={secondaryColor}>
          <Input type="text" placeholder={t('chat.messagePlaceholder')} value={chatMessage} onChange={handleChatMesageChange} onKeyDown={handleKeyDownSubmit} />
          <SubmitButton onClick={handleSubmit}><FontAwesomeIcon icon={faArrowCircleRight} /></SubmitButton>
      </Wrapper>
  )
}

export default ChatMessageInput;