import { useState } from 'react';
import { DateRange } from 'react-date-range';
import moment from "moment";
import SupportTimeSlotService from '../services/SupportTimeSlotService';
import styled from '@emotion/styled';
import specialisationType from '../enums/specialisationType';
import ModalWrapper from './ui/ModalWrapper';
import ModalBody from './ModalBody';
import { getUserColor, getUserSecondaryColor } from '../Utils/Commons'
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const WrapperSpecialisation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-bottom: 10px
`

const SpecialisationButton = styled(Button)`
  width: 100px;
  background-color: ${props => props.active ? props.primaryColor : "white"};
  color: ${props => props.active ? "white" : props.primaryColor};
  border-color: ${props => props.primaryColor};
  &:hover {
    background-color: ${props => props.primaryColor};
    filter: brightness(0.95);
    color: white;
    border-color: ${props => props.primaryColor};
  }
`

const CalendarWrapper = ({ ranges, setRanges, currentUser, selected, handleSelectedDayUpdate, setSelectedRanges, currentSpecialisation, setCurrentSpecialisation, isException, large }) => {
  const [userUpdateRangeVisible, setUserUpdateRangeVisible] = useState(null);
  const [deleteRangeModal, setDeleteRangeModal] = useState(false);
  const [selectedRangeIdToDelete, setSelectedRangeIdToDelete] = useState(null);
  const primaryColor = getUserColor();
  const secondaryColor = getUserSecondaryColor();
  const { t } = useTranslation();
  
  const rangesInDay = (selectedDate) => {
    const selectedEndDate = moment(moment(selectedDate.endDate).format("YYYY-MM-DD"));

    return ranges.filter(r => (selectedEndDate.isBetween(moment(moment(r.startDate).format("YYYY-MM-DD")), moment(moment(r.endDate).format("YYYY-MM-DD")), null, []) && r.exception));
  }

  const handleChange = async (selectedDate) => {
    changeSelection(selectedDate);

    if(isException || !currentUser) return false;

    const foundIndex = ranges.findIndex(r => 
      (r.startDate != null && moment(selectedDate.startDate).isoWeekday() != 1 && moment(selectedDate.endDate).isBetween(moment(r.startDate), moment(r.endDate))) || 
      (moment(selectedDate.endDate).isoWeekday() == 1 && moment(selectedDate.startDate).add(1, 'day').isBetween(moment(r.startDate), moment(r.endDate)))
    );
    if (foundIndex >= 0) {
      rangeFound(foundIndex);
      return;
    }

    await saveNewRange(selectedDate);
  }

  const changeSelection = (selectedDate) => {
    handleSelectedDayUpdate && handleSelectedDayUpdate(selectedDate);

    var found = rangesInDay(selectedDate);

    setSelectedRanges(found);
  }

  const rangeFound = (index) => {
    if(ranges[index].userId == currentUser.id) {
      setSelectedRangeIdToDelete(index);
      setDeleteRangeModal(true);
      return;
    }
    
    // Update week range for selected user
    if(ranges[index].userId != currentUser.id && !ranges[index].exception) {
      setUserUpdateRangeVisible(index);
      return;
    }
  }

  const saveNewRange = async(selectedDate) => {
    // Add new range
    const dateToUse = moment(selectedDate.endDate).isoWeekday() == 7 ? moment(selectedDate.endDate).subtract(1, 'day') : selectedDate.endDate;
    const startOfWeek = moment(dateToUse).weekday(1).add(8, 'hours').toDate();
    const endOfWeek = moment(dateToUse).weekday(7).add(8, 'hours').toDate();

    const newRange = {
      id: 0,
      startDate: startOfWeek,
      endDate: endOfWeek,
      color: currentUser.color,
      userId: currentUser.id,
      exception: false,
      key: 'selection',
      exceptionSentStatus: 0
    }
    
    // Save changes
    const newRangeId = await saveRange(newRange);

    if(newRangeId) {
      newRange.id = newRangeId;
      const updatedRanges = [...ranges];
      updatedRanges.push(newRange);
      setRanges(updatedRanges);
    }
  }

  const deleteSelectedRange = () => {
    const rangesCopy = [...ranges];
    const rangeFound = ranges[selectedRangeIdToDelete];
    rangesCopy.splice(selectedRangeIdToDelete, 1);

    setSelectedRangeIdToDelete(null);
    setRanges(rangesCopy);
    setDeleteRangeModal(false);

    if (!!rangeFound.id) {
      deleteRange(rangeFound.id);
    }
  }

  const saveRange = async (newRange) => {
    const supportTimeSlotService = new SupportTimeSlotService();
    return await supportTimeSlotService.saveTimeSlots(currentSpecialisation, newRange);
  }

  const deleteRange = async (rangeId) => {
    const supportTimeSlotService = new SupportTimeSlotService();
    await supportTimeSlotService.deleteTimeSlot(rangeId);
  }

  const customDayContent = (day) => {
    var extraDot = null;
    var rangesForDay = rangesInDay({ startDate: moment(day), endDate: moment(day) });
    var sameDaySelected = moment(selected?.endDate).format("YYYY-MM-DD") == moment(day).format("YYYY-MM-DD")

    if (rangesForDay.filter(rd => rd.exception).length > 0) {
      extraDot = (
        <div
          style={{
            height: "5px",
            width: "5px",
            borderRadius: "100%",
            background: "red",
            position: "absolute",
            top: 2,
            right: 2
          }}
        />
      )
    }
    return (
      <div>
        {extraDot}
        <span style={{ fontWeight: sameDaySelected ? 900 : 400, color: sameDaySelected ? 'black' : '' }}>{moment(day).format("D")}</span>
      </div>
    )
  }

  const handleReplaceRangeUser = async() => {
    if(!ranges[userUpdateRangeVisible].id) return;

    const supportTimeSlotService = new SupportTimeSlotService();
    await supportTimeSlotService.updateUserRange(ranges[userUpdateRangeVisible].id, currentUser.id);

    // Update range
    const updatedRanges = [...ranges];
    updatedRanges[userUpdateRangeVisible].userId = currentUser.id;
    updatedRanges[userUpdateRangeVisible].color = currentUser.color;
    setRanges(updatedRanges);

    setUserUpdateRangeVisible(null);
  }

  const renderUpdateRangeModal = () => {
    return (
      <ModalWrapper color={secondaryColor}>
        <ModalBody 
          text={t('calendar.replaceUserModal.bodyText')}
          button1Color={primaryColor}
          button1Text={t('calendar.replaceUserModal.cancel')}
          button1Action={() => setUserUpdateRangeVisible(null)}
          button2Color={primaryColor}
          button2Text={t('calendar.replaceUserModal.replace')}
          button2Action={() => handleReplaceRangeUser()} />
      </ModalWrapper>
    )
  }

  return (
    <>
      {deleteRangeModal && 
        <ModalWrapper color={secondaryColor}>
          <ModalBody 
            text={t('calendar.deleteExceptionModal.bodyText')} 
            button1Text={t('calendar.deleteExceptionModal.cancel')}
            button1Action={() => setDeleteRangeModal(false)}
            button1Color={primaryColor}
            button2Text={t('calendar.deleteExceptionModal.delete')}
            button2Action={() => deleteSelectedRange()}
            button2Color={primaryColor}
          />
        </ModalWrapper>
      }
      {userUpdateRangeVisible != null && renderUpdateRangeModal()}
      <Wrapper>
        <WrapperSpecialisation>
          <SpecialisationButton 
            primaryColor={primaryColor}
            variant={currentSpecialisation === specialisationType.PLC ? "contained" : "outlined"} 
            active={currentSpecialisation === specialisationType.PLC} 
            onClick={() => setCurrentSpecialisation(specialisationType.PLC)}
          >
            PLC
          </SpecialisationButton>
          <SpecialisationButton 
            primaryColor={primaryColor}
            variant={currentSpecialisation === specialisationType.SCADA ? "contained" : "outlined"} 
            active={currentSpecialisation === specialisationType.SCADA} 
            onClick={() => setCurrentSpecialisation(specialisationType.SCADA)}
          >
            SCADA
          </SpecialisationButton>
        </WrapperSpecialisation>
        <DateRange
          className={large ? 'xlCalendar' : ''}
          editableDateInputs={true}
          showSelectionPreview={false}
          showPreview={false}
          onChange={item => handleChange(item.selection)}
          dayContentRenderer={(day) => customDayContent(day)}
          ranges={ranges}
          preventSnapRefocus
          preventFocusOnDateChange
          focusedRange={[0, 0]}
        />
      </Wrapper>
    </>
  )
}

export default CalendarWrapper;