import axios from 'axios';
import { getToken } from '../Utils/Commons';

class ConversationStatusRepository {
  updateConversationStatus = async (status, conversationId) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/ConversationStatus.php`,
      { jwt: token, status, conversationId, mode: 'updateStatus' },
      { headers }
    )
    .then(response => {
      
    }).catch(error => {
      // Handle error back to the service > login page
    });
  }

  getConversationStatus = async (conversationId) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    var status = [];
    await axios.get(
      `${process.env.REACT_APP_API_URL}api/ConversationStatus.php?jwt=${token}&conversationId=${conversationId}`,
      { headers }
    )
    .then(response => {
      status = response.data.status;
    }).catch(error => {
      // Handle error back to the service > login page
    });

    return status;
  }
}

export default ConversationStatusRepository;