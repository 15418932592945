import { getToken, getUser } from "../Utils/Commons";
import axios from "axios";

class ClientRepository {
    getClients = async () => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
  
      var clients = null;
      var token = getToken();
      await axios.get(
        `${process.env.REACT_APP_API_URL}api/Client.php?jwt=${token}&mode=clients`,
        { headers }
      )
      .then(response => {
        clients = response.data.clients;
      }).catch(error => {
        // Handle error back to the service > login page
      });
  
      return clients;
    }

  getClientsFilter = async () => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var clients = null;
    var token = getToken();
    await axios.get(
        `${process.env.REACT_APP_API_URL}api/Client.php?jwt=${token}&mode=clientsFilter`,
        { headers }
    )
        .then(response => {
          clients = response.data.clients;
        }).catch(error => {
          // Handle error back to the service > login page
        });

    return clients;
  }

    getClient = async (clientId) => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
  
      var client = null;
      var token = getToken();
      await axios.get(
        `${process.env.REACT_APP_API_URL}api/Client.php?jwt=${token}&clientId=${clientId}&mode=client`,
        { headers }
      )
      .then(response => {
        client = response.data.client;
      }).catch(error => {
        // Handle error back to the service > login page
      });
  
      return client;
    }

    getAnswerers = async () => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
  
      var res = null;
      var token = getToken();
      await axios.get(
        `${process.env.REACT_APP_API_URL}api/Client.php?jwt=${token}&mode=answerers`,
        { headers }
      )
      .then(response => {
        res = {
          success: true,
          value: response.data.answerers
        };
      }).catch(error => {
        res = {
          success: false
        };
      });
  
      return res;
    }

    saveClient = async (client) => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
  
      var res = null;
      var token = getToken();
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/Client.php`,
        { headers, jwt: token, client: JSON.stringify(client), mode: 'saveClient' }
      )
      .then(response => {
        if((typeof response.data === "string" && response.data.includes("Duplicate entry")) || response.data.clientId === false){
          res = {
            success: false,
            message: "This username already exists.",
            error: "USERNAME_EXISTS"
          }
        } else {
          res = {
            success: true,
            clientId: response.data.clientId
          }
        }
      }).catch(error => {
        res = {
          success: false,
          message: error.response.data.message
        }
      });

      return res;
    }

    saveResetPassword = async (form) => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
  
      var res = null;
      var token = getToken();
      const client = getUser();
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/Client.php`,
        { headers, jwt: token, form: JSON.stringify(form), userId: client.id, mode: 'resetClientPsw'}
      )
      .then(response => {
        res = {
          success: true,
          clientId: response.data.pswReset
        }
      }).catch(error => {
        res = {
          success: false,
          message: error.response.data.message
        }
      });

      return res;
    }
  }
  
  export default ClientRepository;