import styled from '@emotion/styled';
import { getUserSecondaryColor, getUserColor } from '../Utils/Commons';
import Checkbox from '../components/ui/Checkbox';
import UserColor from './UserColor';

const Wrapper = styled.div`
  height: 100px;
  width: 100%;
  border-radius: 3px;
  background-color: ${props => props.color};

  display: flex;
  flex-direction: row;
  box-shadow: 3px 3px 5px -3px;
`

const AnswererData = styled.div`
  flex: 2;

  & > div {
    @media (min-width: 601px) {
      margin: 20px 20px;
    }

    @media (max-width: 600px) {
      margin: 20px 5px;
    }
  }
`

const AnswererCheckbox = styled.div`
  @media (min-width: 601px) {
    margin: 30px 20px;
  }

  @media (max-width: 600px) {
    margin: 30px 5px;
  }
`

const AnswererName = styled.div`
  display: flex;
  flex-direction: row;
`

const AnswererCard = ({answerer: answerer, handleChange}) => {
  const secondaryColor = getUserSecondaryColor();
  const primaryColor = getUserColor();

  return (
    <Wrapper color={secondaryColor}>
      <AnswererData>
        <div>
          <AnswererName>
            {answerer.name}
            <UserColor color={answerer.color} />
          </AnswererName>
          <div>{answerer.email}</div>
          <div>{answerer.phoneNumber}</div>
        </div>
      </AnswererData>
      <AnswererCheckbox>
        <Checkbox label={"PLC"} color={primaryColor} value={Boolean(answerer.plcSupport)} onChange={() => handleChange(answerer.id, "plcSupport")} />
        <Checkbox label={"SCADA"} color={primaryColor} value={Boolean(answerer.scadaSupport)} onChange={() => handleChange(answerer.id, "scadaSupport")} />
      </AnswererCheckbox>
    </Wrapper>
  )
}

export default AnswererCard;