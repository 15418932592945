import React, { useState } from "react";
import UserColor from "./UserColor";
import moment from "moment";
import styled from '@emotion/styled';
import DateTimePickerMui from "./ui/DateTimePickerMui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SupportTimeSlotService from "../services/SupportTimeSlotService";
import ModalWrapper from "./ui/ModalWrapper";
import ModalBody from "./ModalBody";
import { getUserColor, getUserSecondaryColor} from "../Utils/Commons"
import { useTranslation } from "react-i18next";

const ExceptionDateRow = styled.div`
  display: flex;
  @media (max-width: 500px) {
    flex-direction: column;
    width: 80%;
  }
  & > div {
    margin: 5px
  }
`

const CalendarRangesDetail = ({ranges, setRanges, updatedRanges, currentSpecialisation, editMode, exceptions, users}) => {
  const [modalDeleteVisible, setModalDeleteVisible] = useState(false);
  const [selectedToDelete, setSelectedToDelete] = useState(null);
  const primaryColor = getUserColor();
  const secondaryColor = getUserSecondaryColor();
  const { t } = useTranslation();
  
  const renderRange = (range, i) => {
    if(!editMode) return renderReadOnly(range, i);

    return renderEditableRange(range, i);
  }

  const renderReadOnly = (range, i) => {
    return (
      <div key={i} style={{display: 'flex', paddingLeft: '10px'}}>
        <UserColor color={range.color} />
        {moment(range.startDate).format("MM/DD HH:mm") + " - " + moment(range.endDate).format("MM/DD HH:mm") + getInitial(range.userId)}
      </div>
    )
  }

  const getInitial = (userId) => {
    const user = users.find(u => u.id === userId);

    return user && ` (${user.firstname.charAt(0)}${user.lastname.charAt(0)})`;
  }

  const renderEditableRange = (range, i) => {
    return (
      <div key={i} style={{display: 'flex', paddingLeft: '10px', justifyContent: 'space-between', paddingTop: '10px'}}>
        <UserColor color={range.color} />
        <ExceptionDateRow>
          <DateTimePickerMui utc label={t('calendar.start')} value={range.startDate} handleChange={(v) =>  handleDateChange('startDate', v, i)} />
          <DateTimePickerMui label={t('calendar.end')} value={range.endDate} handleChange={(v) =>  handleDateChange('endDate', v, i)} />
        </ExceptionDateRow>
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '5px'}} onClick={() => handleDelete(range.id)}>
          <FontAwesomeIcon icon={faTrashAlt} />
        </div>
      </div>
    )
  }

  const handleDateChange = async (position, value, i) => {
    const rangesCopy = [...ranges];
    rangesCopy[i][position] = moment(value).toDate();

    setRanges(rangesCopy);

    await updateRanges(rangesCopy[i]);
  }

  const updateRanges = async (range) => {
    const supportTimeSlotService = new SupportTimeSlotService();
    await supportTimeSlotService.saveTimeSlots(currentSpecialisation, range);
  }

  const handleDelete = (rangeId) => {
    setSelectedToDelete(rangeId);
    setModalDeleteVisible(true);
  }

  const handleCancelDelete = () => {
    setSelectedToDelete(null);
    setModalDeleteVisible(false);
  }

  const deleteRange = async () => {
    const supportTimeSlotService = new SupportTimeSlotService();
    await supportTimeSlotService.deleteTimeSlot(selectedToDelete);

    updatedRanges(selectedToDelete);

    setSelectedToDelete(null);
    setModalDeleteVisible(false);
  }

  const conditions = () => {
    return (exceptions && ranges.filter(sr => sr.exception).length > 0) || (!exceptions && ranges.length > 0)
  }

  const rangesToRender = () => {
    return exceptions ? ranges.filter(sr => sr.exception) : ranges;
  }

  const renderDeleteModal = () => {
    return (
      <ModalWrapper color={secondaryColor}>
        <ModalBody
          button1Color={primaryColor}
          button1Action={handleCancelDelete}
          button1Text={t('calendar.deleteExceptionModal.cancel')}  
          text={t('calendar.deleteExceptionModal.bodyText')}
          button2Color={primaryColor}
          button2Action={deleteRange}
          button2Text={t('calendar.deleteExceptionModal.delete')} />
      </ModalWrapper>
    )
  }

  return (
    <>
      {modalDeleteVisible && renderDeleteModal()}
      {conditions() && <div>
        {exceptions ? t('calendar.dayException') : t('calendar.dayRange')}
        {rangesToRender().map((sr, i) => (
          renderRange(sr, i)
        ))}
      </div>}
    </>
  )
}

export default CalendarRangesDetail;