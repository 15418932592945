import React, {useEffect, useState} from "react";
import styled from '@emotion/styled';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import ModalBackdrop from "./ModalBackdrop";
import Input from '../components/ui/Input';
import NoteService from "../services/NoteService";
import {noteMaxLength} from "../global_variables";

// const Input = styled.textarea`
//   border: none;
//   outline: none;
//   padding: 10px;
//   flex: 1;
//   border-radius: 3px;
// `

const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 0;
`

const NoteWrapper = styled.div(
    {
        padding: "10px",
        flexDirection: "column",
        display: "flex",
        borderRadius: "3px",
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        minWidth: "300px",
        maxHeight: "85%",
        overflowY: "auto",
        zIndex: "9",
        boxShadow: "rgba(0, 0, 0, 0.25) 3.95px 3.95px 5px"
    }, props => ({backgroundColor: props.color})
)

const IconButton = styled.div`
    margin: auto;
    padding: 5px 60px;
    border: 1px solid black;
`

const NoteModal = ({color, closeNoteModal, conversationId}) => {
    const {t} = useTranslation();
    const [noteInfo, setNoteInfo] = useState({id: 0, noteMessage: ""});
    const [noteMessage, setNoteMessage] = useState("");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const noteService = new NoteService();
        const noteData = await noteService.getNote(conversationId);

        setNoteInfo(noteData);
        setNoteMessage(noteData.noteMessage);
    }

    const handleSubmit = async () => {
        const noteService = new NoteService();

        if(noteInfo.id > 0) {
            noteInfo.noteMessage = noteMessage;
            await noteService.updateNote(noteInfo);
        } else {
            await noteService.addNote(noteMessage, conversationId);
        }

        closeNoteModal();
    }

    const handleOnChange = (value, bindOn, event) => {
        scrollToBottom(event);
        // Prevent the user from typing if the limit has been reached but allow to remove with backspace.
        if(value.length > noteMaxLength && event.key !== "Backspace"){
            event.preventDefault();
            return false;
        }

        bindOn(value.substring(0, noteMaxLength));
    }

    const handleDelete = async () => {
        const noteService = new NoteService();

        if(noteInfo.id > 0) {
            await noteService.deleteNote(noteInfo.conversationId);
        }

        closeNoteModal();
    }

    const scrollToBottom = (event) => {
        //Browser is so fast that your scroll() action fires before its default scroll to html anchor event. So it needs a delay
        let element = event.target;
        //Need to get the 6th parentElement to use the scroll
        for (let i = 0; i < 6; i++){
            element = element.parentElement;
        }

        const isNearBottom = isUserNearBottom(element);
        if(isNearBottom){
            setTimeout(function() {
                element.scroll(0, event.target.offsetHeight);
            },1);
        }
    }

    const isUserNearBottom = (element) => {
        const threshold = 50;
        const position = element.scrollTop + element.offsetHeight;
        const height = element.scrollHeight;
        return position > height - threshold;
    }

    return (
        <>
            <ModalBackdrop setStateValue={closeNoteModal}/>
            <NoteWrapper color={color}>
                <Row style={{justifyContent: 'flex-end'}}>
                    <div onClick={() => closeNoteModal()} style={{padding: "5px 10px"}}><FontAwesomeIcon icon={faTimes} /></div>
                </Row>
                <Row>
                    <Input placeholder={t('note.notePlaceholder')} value={noteMessage} bindOn={setNoteMessage}
                           onChange={handleOnChange} multiline rows={5} fullWidth />
                </Row>
                <Row style={{justifyContent: 'center'}}>
                    <IconButton onClick={handleSubmit}><FontAwesomeIcon icon={faSave} /></IconButton>
                    <IconButton onClick={handleDelete}><FontAwesomeIcon icon={faTrash} /></IconButton>
                </Row>
            </NoteWrapper>
        </>
    )
}

export default NoteModal;
