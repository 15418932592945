import styled from '@emotion/styled';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUsersSlash} from "@fortawesome/free-solid-svg-icons";

const Row = styled.div`
  padding: 3px;
`

const Label = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-right: 10px;
`

const SelectWrapper = styled.select`
  border-radius: 5px;
  padding-left: 10px;
  outline: none;
  margin-right: 10px;
  width: -webkit-fill-available;
  height: 36px;
  flex: 1;
  border: 2px solid black;
  -webkit-appearance:none;
`

const InputPlaceholder = styled.div`
  font-size: 10px;
  color: #a9a9a9;
`

const SelectMui = ({data, onChange, value, label, bindOn, placeholder, verifyActive}) => {
  const getOptions = () => {
    return data.map(f => {
      return {'key': f.id, 'text': f.name, 'isActive': verifyActive ? f.isActive : null}
    })
  }

  return (
    <Row>
      <FormControl fullWidth>
        <InputLabel>{placeholder}</InputLabel>
        <Select
          value={value || ""}
          label={placeholder}
          onChange={(v) => onChange(v.target.value, bindOn)}
        >
          {data && getOptions().map(o =>
            <MenuItem key={o.key} value={o.key}>
              {o.text}
              {verifyActive && !o.isActive &&
                  <FontAwesomeIcon icon={faUsersSlash} style={{marginLeft: 10}}></FontAwesomeIcon>
              }
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </Row>
    // <Row>
    //   {label && <Label>{label}:</Label>}
    //   <div style={{width: '-webkit-fill-available'}}>
    //     <SelectWrapper onChange={(v) => onChange(v.target.value, bindOn)} defaultValue={'DEFAULT'} value={value}>
    //       <option value="DEFAULT">
    //         {placeholder}
    //       </option>
    //       {data && getOptions().map(o =>
    //         <option key={o.key} value={o.key}>
    //           {o.text}
    //         </option>
    //       )}
    //     </SelectWrapper>
    //     {value !== "" && value && <InputPlaceholder>{placeholder}</InputPlaceholder>}
    //   </div>
    // </Row>
  )
}

SelectMui.defaultProps = {
  type: 'text'
}

export default SelectMui;