import styled from '@emotion/styled';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import { getUserColor, getUserSecondaryColor } from '../Utils/Commons';
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {FormControlLabel} from "@mui/material";
import settingsStepsEnum from "../enums/settingsStepsEnum";

const Wrapper = styled.div(
  {
    borderRadius: '3px',
    padding: '10px',
    display: 'flex'
  }, props => ({backgroundColor: props.color})
)

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`

const StepOption = styled.div`
  display: flex;
  flex-direction: row;
`

const StepLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const StepDetail = styled.div`
  width: 100%;
`

const Trash = styled.div`
  min-width: 30px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const AlertStep = ({step, index, handleStepUpdate, handleDeleteStep}) => {
  const { t } = useTranslation();
  const primaryColor = getUserColor();
  const secondaryColor = getUserSecondaryColor();
  const handleChange = (event) => {
    step.callOption = event.target.value;
    handleStepUpdate(index, step);
  }

  return (  
  <Wrapper color={secondaryColor}>
    <StepDetail>
      <div>{t('settings.settingSteps.step')} #{index+1}</div>
      <OptionWrapper>
        <RadioGroup row defaultValue={step.callOption} onChange={handleChange}>
          <FormControlLabel value={settingsStepsEnum.FIRST_ANSWERER} control={<Radio />} label={t('settings.settingSteps.firstAnswerer')} />
          <FormControlLabel value={settingsStepsEnum.SECOND_ANSWERER} control={<Radio />} label={t('settings.settingSteps.secondAnswerer')} />
          <FormControlLabel value={settingsStepsEnum.ADMINISTRATOR} control={<Radio />} label={t('settings.settingSteps.administrator')} />
        </RadioGroup>
      </OptionWrapper>
    </StepDetail>
    <Trash>
      <FontAwesomeIcon icon={faTrashAlt} onClick={() => handleDeleteStep(index)} />
    </Trash>
  </Wrapper>
  )
}

export default AlertStep;