import styled from '@emotion/styled';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wrapper = styled.div(
  {
    padding: "5px 0px",
    display: "flex"
  }, props => ({marginBottom: props.last ? '15px' : ''})
)

const WrapperIcon = styled.div`
  width: 23px;
`


const MenuOption = ({ icon, label, changeStatus, last }) => {
  return (
    <Wrapper onClick={() => changeStatus()} last={last}>
      <WrapperIcon>
        <FontAwesomeIcon icon={icon} />
      </WrapperIcon>
      <div>
        {label}
      </div>
    </Wrapper>
  )
}

export default MenuOption;