import React from "react";
import { getUserColor, getUserSecondaryColor} from "../Utils/Commons"
import {useTranslation} from "react-i18next";

import ModalWrapper from "./ui/ModalWrapper";
import ModalBody from "./ModalBody";

const SupportHebdoModal = ({handleRedirection}) => {
    const { t } = useTranslation();
    const primaryColor = getUserColor();
    const secondaryColor = getUserSecondaryColor();

    return (
        <ModalWrapper color={secondaryColor}>
            <ModalBody
                text={t('chat.newForm.support85.header') + "\n" + t('chat.newForm.support85.message')}
                button1Color={primaryColor}
                button1Action={handleRedirection}
                button1Text="Ok" />
        </ModalWrapper>
    )
}

export default SupportHebdoModal;