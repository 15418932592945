import React, { useState } from "react";
import styled from '@emotion/styled';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneSlash } from "@fortawesome/free-solid-svg-icons";
import {getUserColor, getUserSecondaryColor, userHasPermission} from '../Utils/Commons';
import ConversationService from "../services/ConversationService";
import { useParams } from "react-router-dom";
import ModalWrapper from "./ui/ModalWrapper";
import ModalBody from "./ModalBody";
import {useTranslation} from "react-i18next";
import IconButton from '@mui/material/IconButton';

const EditIconWrapper = styled(IconButton)`
  position: relative;
  right: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  &:hover {
    cursor: pointer;
  }
`

const CancelAlertButton = ({setCurrentALertsFalse}) => {
  const [errorCancellingModal, setErrorCancellingModal] = useState(false);
  const [cancelWarning, setCancelWarning] = useState(false);
  const conversationId = useParams().conversationId || 0;
  const userColor = getUserColor();
  const userSecondaryColor = getUserSecondaryColor();
  const { t } = useTranslation();

  const cancelAlert = async () => {
    setCancelWarning(false);

    const conversationService = new ConversationService();
    const res = await conversationService.cancelAlert(conversationId);

    if(!res.success) {
      setErrorCancellingModal(true);
    }

    setCurrentALertsFalse();
  }

  const handleErrorModal = () => {
    setErrorCancellingModal(false);
  }

  const handleCancelWarning = () => {
    setCancelWarning(true);
  }

  const errorModal = () => {
    return (
      <span>
        <ModalWrapper color={userSecondaryColor}>
          <ModalBody 
            text={t('chat.errorCancellingAlerts')}
            button1Color={userColor}
            button1Action={() => handleErrorModal()}
            button1Text="Ok" />
        </ModalWrapper>
      </span>
    )
  }

  const warningModal = () => {
    return (
      <span>
        <ModalWrapper color={userSecondaryColor}>
          <ModalBody 
            text={t('chat.warningCancellingAlerts')}
            button1Color={userColor}
            button1Action={() => setCancelWarning(false)}
            button1Text={t('chat.warningCancellingAlertsCloseModalButton')}
            button2Color={userColor}
            button2Action={() => cancelAlert()}
            button2Text={t('chat.warningCancellingAlertsCancelButton')} />
        </ModalWrapper>
      </span>
    )
  }

  return (
    <>
      {errorCancellingModal && errorModal()}
      {cancelWarning && warningModal()}
      { userHasPermission("canCancelWarning") &&
        <EditIconWrapper onClick={handleCancelWarning}>
          <FontAwesomeIcon icon={faPhoneSlash} size="sm" color={userColor} />
        </EditIconWrapper>
      }
    </>
  )
}

export default CancelAlertButton;