import RapportInterventionRepository from "../repositories/RapportInterventionRepository";

class RapportInterventionService {

    repo = new RapportInterventionRepository();

    getRapportByConversationId = async (conversationId) => {
        return await this.repo.getRapportByConversationId(conversationId);
    };

    getAllRapports = async (arrayFilter) => {
        return await this.repo.getAllRapports(arrayFilter);
    };

    saveRapport = async (rapport) => {
        return await this.repo.saveRapport(rapport);
    };

    getNextReportNumber = async (conversationId) => {
        return await this.repo.getNextReportNumber(conversationId);
    };

    sendRapportEmail = async (pdfBlob, codeProjet, conversationId) => {
        return await this.repo.sendRapportEmail(pdfBlob, codeProjet, conversationId);
    }
}

export default RapportInterventionService;