import React, { useEffect, useState } from "react";
import styled from '@emotion/styled';
import DatePicker from 'react-date-picker'
import {useTranslation} from "react-i18next";
import FactoryService from "../services/FactoryService";
import ClientService from "../services/ClientService";
import LogTestConnectionService from "../services/LogTestConnectionService";
import SpecialisationType from "../enums/specialisationType";
import Box from "@mui/material/Box";
import {darken, lighten} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment-timezone/moment-timezone-utils";
import {getUser, saveDataInStorage, getValueFromStorage} from "../Utils/Commons";

const currentPageKeyFilter = "testConnectionHistory.";

const Wrapper = styled.div`
  padding-top: 10px;
  overflow: auto;
  height: 95vh;
`

const VerticalCenterFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Filter = styled.div`
  margin: 5px;
  width: 300px;
  text-align: center;
`

const getColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getHoverColor = (color: string, mode: string) =>
    mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const TestConnectionHistoryPage = () => {
    const [tests, setTests] = useState([]);
    const [dateFrom, setDateFrom] = useState(getValueFromStorage(currentPageKeyFilter + "dateFrom"));
    const [dateTo, setDateTo] = useState(getValueFromStorage(currentPageKeyFilter + "dateTo"));
    const { t } = useTranslation();

    const columns = [
        { field: 'factoryName', headerName: t('testConnectionHistory.headers.factory'), flex: 1 },
        { field: 'factorySpecialisation', headerName: t('testConnectionHistory.headers.specialisation'), flex: 0.5 },
        { field: 'techInitials', headerName: t('testConnectionHistory.headers.initials'), flex: 0.3 },
        { field: 'logDateShown', headerName: t('testConnectionHistory.headers.date'), flex: 0.7 },
        { field: 'note', headerName: t('testConnectionHistory.headers.note'), flex: 2 },
        { field: 'statusDesc', headerName: t('testConnectionHistory.headers.status'), flex: 0.5 }
    ];

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(()=>{
        fetchData();
    },[dateFrom, dateTo]);

    const fetchData = async () => {
        const dateFromUtc = Date.UTC(dateFrom.getUTCFullYear(), dateFrom.getUTCMonth(), dateFrom.getUTCDate(), 0, 0, 0);
        const dateToUtc = Date.UTC(dateTo.getUTCFullYear(), dateTo.getUTCMonth(), dateTo.getUTCDate(), 23, 59, 59);

        const logTestConnectionService = new LogTestConnectionService();
        const testsData = await logTestConnectionService.getAllHistoryLogs([new Date(dateFromUtc), new Date(dateToUtc)]);

        const factoryService = new FactoryService();
        const factoriesData = await factoryService.getFactories();

        const clientService = new ClientService();
        const clientsData = await clientService.getClients();

        factoriesData.forEach(factory => {
            factory.label = factory.name;
        });

        clientsData.forEach(client => {
            client.label = client.name;
        });

        testsData.forEach(test => {
            const factory = factoriesData.find(factory => factory.id === test.factoryId);
            test.factoryName = factory.name;
            test.factorySpecialisation = factory.specialisationSupport === SpecialisationType.PLC ? "PLC" : "SCADA";
            test.logDateShown = moment(test.logDate + "+00:00").utcOffset(getUser().timeZoneOffset, false).format("YYYY-MM-DD HH:mm");
            test.status = test.isTestDeleted ? "Deleted" : test.isTestCompleted ? "Completed" : "Failed"
            test.statusDesc = t(`testConnectionHistory.testStatus.${test.isTestCompleted ? "Completed" : "Failed"}`);
        });

        setTests(testsData);
    };

    return (
        <Wrapper>
            <div className={"filterContainer"}>
                <VerticalCenterFilters>
                    <Filter className={"inputFilter"} style={{zIndex: 2}}>
                        <label style={{marginRight: "5px", textAlign: "bottom"}}>{t('filters.dateFrom') + ":"}</label>
                        <DatePicker onChange={(value) => { saveDataInStorage(currentPageKeyFilter + "dateFrom", value.getTime()); setDateFrom(value) }}
                                    value={dateFrom} format={"y-MM-dd"} clearIcon={null} maxDate={dateTo}/>
                    </Filter>
                    <Filter className={"inputFilter"} style={{zIndex: 2}}>
                        <label style={{marginRight: "5px", textAlign: "bottom"}}>{t('filters.dateTo') + ":"}</label>
                        <DatePicker onChange={(value) => { saveDataInStorage(currentPageKeyFilter + "dateTo", value.getTime()); setDateTo(value) }}
                                    value={dateTo} format={"y-MM-dd"} clearIcon={null} minDate={dateFrom}/>
                    </Filter>
                </VerticalCenterFilters>
            </div>
            <hr className={"filterContainer"}/>
            <div style={{width: "1200px", height: "90%", margin: "auto", marginTop: "25px"}}>
                <Box
                    sx={{
                        height: "100%",
                        width: '100%',
                        '& .test-connection-theme--Completed': {
                            color: "green"
                        },
                        '& .test-connection-theme--Failed': {
                            color: "red"
                        },
                        '& .test-connection-theme--Deleted': {
                            textDecoration: "line-through"
                        },
                    }}
                >
                    <DataGrid
                        rows={tests} columns={columns} getRowHeight={() => 'auto'}
                        getRowClassName={(params) => `test-connection-theme--${params.row.status}`}
                    />
                </Box>
            </div>
        </Wrapper>
    )
}

export default TestConnectionHistoryPage;