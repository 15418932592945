import styled from '@emotion/styled';
import UserColor from './UserColor';
import {userHasPermission} from "../Utils/Commons";

const Row = styled.div(
  {
  display: 'flex',
  flexDirection: 'row',
  paddingTop: '3px'
}, props => ({
  fontWeight: props.selected ? 700 : 400
})
)

const setUser = (user, currentUser, setCurrentUser) => {
    if (!userHasPermission('canSeeConfig')) { return; }
    setCurrentUser(currentUser === user ? null : user)
};

const CalendarUsersList = ({users, currentUser, setCurrentUser}) => {
  return (
    <div>
      {users.map((user, i) => (
        <Row selected={currentUser === user} key={i} onClick={() => setUser(user, currentUser, setCurrentUser)}>
          <UserColor color={user.color} />
          <div>{user.firstname} {user.lastname}</div>
        </Row>
      ))}
    </div>
  )
}

export default CalendarUsersList;