import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import DateTimePickerMui from '../components/ui/DateTimePickerMui';
import CalendarWrapper from '../components/CalendarWrapper';
import Button from '../components/ui/Button';
import specialisationType from '../enums/specialisationType';
import UseFetchCalendarRanges from '../hooks/UseFetchCalendarRanges';
import CalendarUsersList from '../components/CalendarUsersList';
import SupportTimeSlotService from '../services/SupportTimeSlotService';
import CalendarRangesDetail from '../components/CalendarRangesDetail';
import { getUserColor, getUserSecondaryColor } from '../Utils/Commons';
import ModalWrapper from '../components/ui/ModalWrapper';
import ModalBody from '../components/ModalBody';

const StepOption = styled.div`
  display: flex;
  flex-direction: row;
`

const Wrapper = styled.div`
  @media (min-width: 550px) {
    width: 550px;
  }
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`

const ExceptionDateRow = styled.div`
  display: flex;
  padding: 5px 0px;
  & > div {
    margin-right: 5px;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    width: 80%;
    & > div {
      margin: 5px 0px;
    }
  }
`

const CalendarPage = () => {
  const defaultRange = {
    startDate: null,
    endDate: new Date(""),
    key: 'selection',
    exceptionSentStatus: 0
  };
  const [currentSpecialisation, setCurrentSpecialisation] = useState(specialisationType.PLC);
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([])
  const [selected, setSelected] = useState(moment());
  const [selectedRanges, setSelectedRanges] = useState([]);
  const [isException, setIsException] = useState(true);
  const [newEntry, setNewEntry] = useState({ from: null, to: null });
  const [userUndefinedExceptionModal, setUserUndefinedExceptionModal] = useState(false);
  var [ranges, setRanges] = useState([defaultRange]);
  const { t } = useTranslation();
  const primaryColor = getUserColor();
  const secondaryColor = getUserSecondaryColor();

  useEffect(() => {
    fetchData();
  }, [currentSpecialisation])

  useEffect(() => {
    const selectedEndDate = selected.endDate;

    const tempNewEntry = {
      from: moment(moment(selectedEndDate).format("yyyy-MM-DD")).add(8, 'hours'),
      end: moment(moment(selectedEndDate).format("yyyy-MM-DD")).add(9, 'hours')
    }

    setNewEntry(tempNewEntry);
  }, [selected])

  const fetchData = async () => {
    const fetchedData = await UseFetchCalendarRanges(currentSpecialisation);
    
    setUsers(fetchedData.supportTimeSlotsData.users);
    setRanges(fetchedData.ranges);
    setCurrentUser(null);
  }

  const handleSelectedDayUpdate = (date) => {
    setSelected(date, moment(date.endDate));
  }

  const saveException = async () => {
    if (!currentUser) { 
      setUserUndefinedExceptionModal(true);
      return;
    }

    const newException = {
      id: 0,
      startDate: moment.utc(newEntry.from),
      endDate: moment.utc(newEntry.end),
      color: currentUser.color,
      userId: currentUser.id,
      exception: true,
      key: 'selection',
      exceptionSentStatus: 0
    }

    const supportTimeSlotService = new SupportTimeSlotService();
    const insertedId = await supportTimeSlotService.saveTimeSlots(currentSpecialisation, newException);

    // Add exception to ranges
    const rangesCopy = [...ranges];
    if(insertedId) {
      newException.id = insertedId;
    }
    newException.startDate = moment(newEntry.from).toDate();
    newException.endDate = moment(newEntry.end).toDate();
    rangesCopy.push(newException);
    setRanges(rangesCopy);

    // Add to selected ranges details for the day
    const selectedRangesCopy = [...selectedRanges];
    selectedRangesCopy.push(newException);
    setSelectedRanges(selectedRangesCopy);

    //Reset exception and user selected
    setIsException(true);
    setCurrentUser(null);
  }

  const handleFromDateChange = (value) => {
    setNewEntry({ from: moment(value), end:moment(value).add(1, 'hour') })
  }

  const handleEndDateChange = (value) => {
    setNewEntry({ from: newEntry.from, end: moment(value) })
  }

  const removeFromRange = (rangeId) => {
    const rangesCopy = [...ranges];
    const validRanges = rangesCopy.filter(rc => rc.id != rangeId);
    setRanges(validRanges);
    setSelected(moment());
    setSelectedRanges([]);
  }

  return (
    <Wrapper>
      {userUndefinedExceptionModal && <ModalWrapper color={secondaryColor}>
        <ModalBody 
          text={t('calendar.userExceptionModal.bodyText')}
          button1Action={() => setUserUndefinedExceptionModal(false)}
          button1Text={t('calendar.userExceptionModal.ok')}
          />
        </ModalWrapper>
      }
      <CalendarWrapper
        ranges={ranges}
        setRanges={setRanges}
        currentUser={currentUser}
        selected={selected}
        handleSelectedDayUpdate={handleSelectedDayUpdate}
        setSelectedRanges={setSelectedRanges}
        currentSpecialisation={currentSpecialisation}
        setCurrentSpecialisation={setCurrentSpecialisation}
        isException={isException}
        large
      />
      <StepOption>
        <FormControlLabel 
          style={{marginLeft: "0px"}}
          control={
            <Checkbox 
            checked={isException} 
            onClick={() => setIsException(!isException)} 
            sx={{
              color: primaryColor,
              '&.Mui-checked': {
                color: primaryColor,
              }
            }}
          />} 
          label={t('calendar.exception')} 
          labelPlacement="start"
        />
      </StepOption>
      <CalendarUsersList users={users} currentUser={currentUser} setCurrentUser={setCurrentUser} />
      <div style={{ paddingTop: '10px' }}>
        {isException && (
          <>
          <ExceptionDateRow>
            <DateTimePickerMui label={t('calendar.start')} value={newEntry.from} handleChange={(v => handleFromDateChange(v))} />
            <DateTimePickerMui label={t('calendar.end')} value={newEntry.end} handleChange={(v => handleEndDateChange(v))} />
          </ExceptionDateRow>
          <Button textContent={t('calendar.save')} action={saveException} />
          </>
        )}
      </div>
      <CalendarRangesDetail 
        ranges={selectedRanges} 
        setRanges={setSelectedRanges} 
        updatedRanges={removeFromRange} 
        currentSpecialisation={currentSpecialisation} 
        exceptions
        editMode
      />
    </Wrapper>
  )
}

export default CalendarPage;