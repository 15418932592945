import RolesRepository from "../repositories/RolesRepository";

class RolesService {
    getRoles = async () => {
        const repo = new RolesRepository();
        return await repo.getRoles();
    }

    addUserRoles = async (userId, roleId) => {
        const repo = new RolesRepository();
        return await repo.addUserRoles(userId, roleId);
    }

    deleteUserRoles = async (userId, roleId) => {
        const repo = new RolesRepository();
        return await repo.deleteUserRoles(userId, roleId);
    }
}

export default RolesService;