import { useEffect, useState } from "react";
import styled from '@emotion/styled';
import UserService from "../services/UserService";
import AnswererCard from "../components/AnswererCard";

const Grid = styled.div`
  margin: 10px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(100px, auto);

  @media (max-width: 600px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const AnswererListPage = () => {
  const [answerers, setAnswerers] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const userService = new UserService();
    const res = await userService.getAnswerers();

    if(res.success) {
      setAnswerers(res.value);
    }
  }

  const handleChange = (answererId, field) => {
    const updatedAnswerers = [...answerers];

    updatedAnswerers.forEach(a => {
      if(a.id === answererId) {
        a[field] = !a[field];
      }
    })

    setAnswerers(updatedAnswerers);

    // Save changes
    saveUpdatedAnswerer(answerers.find(a => a.id === answererId));
  }

  const saveUpdatedAnswerer = async (answerer) => {
    const userService = new UserService();
    const res = await userService.updateAnswerer(answerer.id, answerer.plcSupport, answerer.scadaSupport);

    if(!res.success) {
      // TODO: Add alert
    }
  }

  return (
    <Grid>
      {answerers.map(answerer => (
        <AnswererCard key={answerer.id} answerer={answerer} handleChange={handleChange} />
      ))}
    </Grid>
  )
}

export default AnswererListPage;