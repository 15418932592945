import ClientRepository from "../repositories/ClientRepository";

class ClientService {
  getClients = async () => {
    const repo = new ClientRepository();
    const clients = await repo.getClients();

    return JSON.parse(clients);
  }

  getClientsFilter = async () => {
    const repo = new ClientRepository();
    const clients = await repo.getClientsFilter();

    return JSON.parse(clients);
  }

  getClient = async (clientId) => {
    if(!clientId) return;

    const repo = new ClientRepository();
    const client = await repo.getClient(clientId);

    return JSON.parse(client);
  }

  getAnswerers = async () => {
    const repo = new ClientRepository();
    const clients = await repo.getAnswerers();

    return JSON.parse(clients);
  }

  saveClient = async (client) => {
    if(!client) return;

    const repo = new ClientRepository();
    return await repo.saveClient(client);
  }

  saveResetPassword = async (form) => {
    if(!form.currentPassword || !form.newPassword || !form.newPasswordRetype) return;

    const repo = new ClientRepository();
    return await repo.saveResetPassword(form);
  }
}

export default ClientService;