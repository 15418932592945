
import axios from 'axios';
import { getToken } from '../Utils/Commons';

class SupportTimeSlotRepository {
  getTimeSlots = async (specialisation) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    var timeSlotsdata = null;
    
    await axios.get(
      `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php?jwt=${token}&specialisation=${specialisation}&mode=getTimeSlots`,
      { headers }
    )
      .then(response => {
        timeSlotsdata = response.data;
      }).catch(error => {
        // Handle error back to the service > login page
      });

      return timeSlotsdata
  }

  saveTimeSlots = async (specialisation, range) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    var timeSlotNewId = null;
    
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php`,
      { jwt: token, mode: 'saveNew', specialisation, range },
      { headers }
    )
      .then(response => {
        timeSlotNewId = response.data.id
      }).catch(error => {
        // Handle error back to the service > login page
      });

      return timeSlotNewId
  }

  updateUserRange = async (rangeId, userId) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    var res = null;
    
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php`,
      { jwt: token, mode: 'updateRangeUser', rangeId, userId },
      { headers }
    )
      .then(response => {
        res = {
          success: true
        }
      }).catch(error => {
        res = {
          success: false
        }
      });

      return res
  }

  deleteTimeSlot = async (rangeId) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    var timeSlots = null;
    
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php`,
      { jwt: token, mode: 'deleteTimeSlot', rangeId },
      { headers }
    )
      .then(response => {

      }).catch(error => {
        // Handle error back to the service > login page
      });

      return timeSlots
  }

  getCurrentSupportTeamMember = async (factoryId) => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    var supportName = null;
    
    await axios.get(
      `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php?jwt=${token}&factoryId=${factoryId}&mode=getCurrentSupportTeamMember`,
      { headers }
    )
      .then(response => {
        supportName = response.data;
      }).catch(error => {
        // Handle error back to the service > login page
      });

      return supportName
  }

  getCurrentActiveSupportTeamMembers = async () => {
    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    const token = getToken();
    let supportMembers = null;

    await axios.get(
        `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php?jwt=${token}&mode=getCurrentActiveSupportTeamMembers`,
        { headers }
    )
        .then(response => {
          supportMembers = response.data;
        }).catch(error => {

        });

    return supportMembers
  }

  makeTestCall = async (specialisationType) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    await axios.post(
      `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php`,
      { jwt: token, mode: 'testCall', specialisationType: specialisationType },
      { headers }
    );
  }

  makePersonalTestCall = async (userId) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var token = getToken();
    await axios.post(
        `${process.env.REACT_APP_API_URL}api/SupportTimeSlots.php`,
        { jwt: token, mode: 'personalTestCall', userId: userId },
        { headers }
    );
  }

}

export default SupportTimeSlotRepository;