import FactoryRepository from "../repositories/FactoryRepository";

class FactoryService {
    getFactories = async () => {
        const repo = new FactoryRepository();
        const factories = await repo.getFactories();

        return JSON.parse(factories);
    }

    getFactoriesFilter = async () => {
        const repo = new FactoryRepository();
        const factories = await repo.getFactoriesFilter();

        return JSON.parse(factories);
    }

    getFactory = async (factoryId) => {
        if(!factoryId) return;

        const repo = new FactoryRepository();
        const factory = await repo.getFactory(factoryId);

        return JSON.parse(factory);
    }

    getPotentialDirectors = async () => {
        const repo = new FactoryRepository();
        const potentialDirectors = await repo.getPotentialDirectors();

        return JSON.parse(potentialDirectors);
    }

    saveFactory = async (factory) => {
        if(!factory) return;

        const repo = new FactoryRepository();
        return await repo.saveFactory(factory);
    }
}

export default FactoryService;