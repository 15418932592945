import { getToken } from "../Utils/Commons";
import axios from "axios";

class RolesRepository {
    getRoles = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let settings = null;
        const token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/Roles.php?jwt=${token}&mode=getRoles`,
            { headers }
        )
            .then(response => {
                settings = JSON.parse(response.data.roles);
            }).catch(() => {
                // Handle error back to the service > login page
            });

        return settings;
    }

    addUserRoles = async (userId, roleId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let res = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Roles.php`,
            { headers, jwt: token, userId: userId, roleId: roleId, mode: "addUserRoles" }
        ).then(() => {
            res = {
                success: true
            }
        }).catch(error => {
            res = {
                success: false,
                message: error.response.data.message
            }
        });

        return res;
    };

    deleteUserRoles = async (userId, roleId) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let res = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Roles.php`,
            { headers, jwt: token, userId: userId, roleId: roleId, mode: "deleteUserRoles" }
        ).then(() => {
            res = {
                success: true
            }
        }).catch(error => {
            res = {
                success: false,
                message: error.response.data.message
            }
        });

        return res;
    };
}

export default RolesRepository;