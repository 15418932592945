import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import styled from '@emotion/styled';
import FactoryService from '../services/FactoryService';
import Input from '../components/ui/Input';
import Select from '../components/ui/Select';
import Button from '../components/ui/Button';
import FormRow from "../components/form/FormRow";
import FormWrapper from "../components/form/FormWrapper";
import FormSectionRow from "../components/form/FormSectionRow";
import FormSection from "../components/form/FormSection";
import ClientService from "../services/ClientService";
import {Checkbox, FormControlLabel} from "@mui/material";
import { isIOS } from "react-device-detect";

const Error = styled.div`
  font-size: 12px;
  color: red;
  padding-top: 5px
`

const Success = styled.div`
  font-size: 12px;
  color: green;
  padding-top: 5px
`

const CheckBoxStyles = {
  default: {
    justifyContent: "center",
    border: "1px solid #999",
    margin: "3px",
    borderRadius: "5px",
    width: "98%",
    height: "87.1%"
  },
  iOS: {
    justifyContent: "center",
    border: "1px solid #999",
    margin: "3px",
    borderRadius: "5px",
    width: "95%",
    height: "9.7%",
    minHeight: "50px"
  }
};

const FormTech = styled.div`
  width: 98%;
  display: flex;
  justify-content: space-between;
`;

const TechSelect = styled.div `
  width: stretch;
`;

const TechActive = styled.div `
  
`;

const formFields = ["name", "location", "phoneNumber", "specialisationSupport", "directorId", "contactId", "codeProjet"];

const FactoryPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [factoryId, setFactoryId] = useState(parseInt(useParams().factoryId) || 0);
  const [formValid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [formSuccess, setFormSuccess] = useState();
  const [factory, setFactory] = useState({
    id: 0,
    isActive: true,
    supportHebdo: false,
    techId: 0,
    techIsActive: false,
  });
  const specialisationSupport = [
    {
      'id': 1,
      'name': 'PLC'
    },
    {
      'id': 2,
      'name': 'SCADA'
    },
  ];
    const [potentialDirectors, setPotentialDirectors] = useState([]);
    const [contactApprobation, setContactApprobation] = useState([]);
    const [emailCc1, setEmailCc1] = useState([]);
    const [emailCc2, setEmailCc2] = useState([]);
    const [techniciens, setTechniciens] = useState([]);

  useEffect(async () => {
    if(factoryId !== 0) {
      await fetchFactory();
    } else {
      await fetchUsers();
    }
  }, []);

  const fetchFactory = async () => {
    const factoryService = new FactoryService();
    const factoryData = await factoryService.getFactory(factoryId);

    setFactory(factoryData);
    await fetchUsers();
  }

    const fetchUsers = async () => {
        const factoryService = new FactoryService();
        const potentialDirectors = await factoryService.getPotentialDirectors();

        const clientService = new ClientService();
        let clients = await clientService.getClients();

        const emptyClient = { name: "Aucun", value: null, factoryId: 0, id: 0 };

        let techniciens = clients.filter(client => client.factoryId === 1);
        techniciens.unshift(emptyClient);
        setTechniciens(techniciens);

        clients = clients.filter(client => client.factoryId === factoryId || client.factoryId === 1);
        clients.unshift(emptyClient);

        setPotentialDirectors(potentialDirectors);

        let potentialContact = clients.filter(client => client.factoryId === factoryId);
        if(potentialContact.length === 0){ potentialContact.push(emptyClient); }
        setContactApprobation(potentialContact);

        setEmailCc1(clients);
        setEmailCc2(clients);
    }

  const handleOnChange = (value, bindOn) => {
    const factoryData = {...factory};

    if(bindOn === 'techId' && value === 0) {
      factoryData['techIsActive'] = false;
    }

    factoryData[bindOn] = value;
    setFactory(factoryData);
    setFormValid(true);
  }

  const handleSave = async () => {
    if(!formValidation()) return;

    const factoryService = new FactoryService();
    const res = await factoryService.saveFactory(factory);

    if(!res.success) {
      setFormValid(false);
      setFormErrors([res.message]);
      return;
    }

    if(factoryId === 0) {
      history.replace('factory/'+res.factoryId);
      setFactoryId(res.factoryId);
      
      setTimeout(() => {
        const updatedFactory = {...factory};
        updatedFactory.id = res.factoryId;
        setFactory(updatedFactory);
      }, 1000);
    }

    setFormSuccess("Saved!");
    setTimeout(() => {
      setFormSuccess(null);
    }, 3000);
  }

  const formValidation = () => {
    let valid = true;
    var newErrors = [];

    // Fields check
    formFields.forEach(field => {
      if(field === "contactId"){ return; }
      if(!factory[field] || factory[field] === undefined) {
        setFormValid(false);
        newErrors.push(field+" is missing");
        valid = false;
      }
    })

    setFormErrors(newErrors);

    return valid;
  }

  const handleReturn = () => {
    history.push('/settings');
  }

  const handleOnChangeChecked = (event, champ) => {
    const factoryData = {...factory};
    factoryData[champ] = event.target.checked;
    setFactory(factoryData);
    setFormValid(true);
  }

  return (
    <FormWrapper>
      <FormSectionRow>
        <FormRow>
          {t('factoryPage.factoryInfo')}:
        </FormRow>
        <FormSection>
          <FormRow>
            <Input placeholder={t('factoryPage.name')} value={factory?.name} bindOn='name' onChange={handleOnChange}/>
            <Input placeholder={t('factoryPage.location')} value={factory?.location} bindOn='location' onChange={handleOnChange}/>
          </FormRow>
          <FormRow>
            <Input type="phoneNumber" placeholder={t('factoryPage.phone')}  value={factory?.phoneNumber} bindOn='phoneNumber' onChange={handleOnChange} />
            <Select placeholder={t('factoryPage.specialisation')} value={factory?.specialisationSupport} data={specialisationSupport} bindOn='specialisationSupport' onChange={handleOnChange} />
          </FormRow>
          <FormRow>
            {potentialDirectors.length > 0 &&
              <Select placeholder={t('factoryPage.director')} value={factory?.directorId} data={potentialDirectors} bindOn={'directorId'} onChange={handleOnChange} verifyActive={true} />
            }
            <Select placeholder={t('factoryPage.contact')} value={factory?.contactId} data={contactApprobation} bindOn={'contactId'} onChange={handleOnChange} verifyActive={true} />
          </FormRow>
            <FormRow>
                {emailCc1.length > 0 &&
                    <Select placeholder={t('factoryPage.cc1')} value={factory?.emailCc1Id} data={emailCc1} bindOn={'emailCc1Id'} onChange={handleOnChange} verifyActive={true} />
                }
                {emailCc2.length > 0 &&
                    <Select placeholder={t('factoryPage.cc2')} value={factory?.emailCc2Id} data={emailCc2} bindOn={'emailCc2Id'} onChange={handleOnChange} verifyActive={true} />
                }
            </FormRow>
            <FormRow>
                <Input placeholder={t('factoryPage.codeProjet')} value={factory?.codeProjet} bindOn='codeProjet' onChange={handleOnChange}/>
            </FormRow>
            <FormRow>
              <FormControlLabel label={t('factoryPage.factoryActive')} control={<Checkbox checked={!!factory?.isActive} onChange={(e) => handleOnChangeChecked(e,"isActive")} />}
                                labelPlacement="start" style={ isIOS ? CheckBoxStyles.iOS : CheckBoxStyles.default }/>
              <FormControlLabel label={t('factoryPage.supportHebdo')} control={<Checkbox checked={!!factory?.supportHebdo} onChange={(e) => handleOnChangeChecked(e,"supportHebdo")} />}
                                labelPlacement="start" style={ isIOS ? CheckBoxStyles.iOS : CheckBoxStyles.default }/>
            </FormRow>
        </FormSection>
      </FormSectionRow>
      <FormSectionRow>
        <FormRow>
          {t('factoryPage.priorityTechnician')}
        </FormRow>
        <FormSection>
          <FormRow>
            <FormTech>
              <TechSelect>
                {techniciens.length > 0 &&
                    <Select placeholder={t('filters.technician')} value={factory?.techId} data={techniciens} bindOn={'techId'} onChange={handleOnChange} verifyActive={true} />
                }
              </TechSelect>
              <TechActive>
                <FormControlLabel label={""} control={<Checkbox checked={!!factory.techIsActive} onChange={(e) => handleOnChangeChecked(e,"techIsActive")} disabled={factory.techId === null || factory?.techId === 0 }/>}
                                  labelPlacement="start" style={ isIOS ? CheckBoxStyles.iOS : CheckBoxStyles.default }/>
              </TechActive>
            </FormTech>
          </FormRow>
        </FormSection>
      </FormSectionRow>
      <Button textContent={t('factoryPage.save')} action={handleSave} />
      <Button textContent={t('settings.return')} action={handleReturn} />
      {!formValid && formErrors.map((error, i) => (
        <Error key={i}>{error}</Error>
      ))}
      {formSuccess && <Success>{formSuccess}</Success>}
    </FormWrapper>
  )
}

export default FactoryPage;