// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user');
  if (userStr) return JSON.parse(userStr);
  else return null;
}

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem('token') || null;
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('user');
}

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem('token', token);
  sessionStorage.setItem('user', JSON.stringify(user));
}

export const userHasPermission = (permission) => {
    const user = getUser();
    let hasPerm = false;

    user?.roles?.forEach((role) => {
        if(role.permissions.find(perm => perm.permission === permission) !== undefined){
            hasPerm = true;
        }
    })

    return hasPerm;
};

export const setUserColor = (primaryColor, secondaryColor, textColor) => {
  sessionStorage.setItem('primaryColor', primaryColor);
  sessionStorage.setItem('secondaryColor', secondaryColor);
  sessionStorage.setItem('textColor', textColor);
}

export const removeUserColor = () => {
  sessionStorage.removeItem('secondaryColor');
}

export const getUserColor = () => {
  return sessionStorage.getItem('primaryColor') || null;
}

export const getUserSecondaryColor = () => {
  return sessionStorage.getItem('secondaryColor') || null;
}

export const getUserTextColor = () => {
  return sessionStorage.getItem('textColor') || null;
}

export const saveDataInStorage = (key, value) => {
    if(value.length > 0 || !isNaN(value)){
        sessionStorage.setItem(key, value);
    }
};

export const getValueFromStorage = (key) => {
    let value;
    let date;

    if (key.includes("dateFrom")) {
        date = parseInt(sessionStorage.getItem(key));
        value = (isNaN(date) ? new Date(new Date().getFullYear(), new Date().getMonth() - 2, new Date().getDate()) : new Date(date));
    } else if (key.includes("dateTo")) {
        date = parseInt(sessionStorage.getItem(key));
        value = (isNaN(date) ? new Date() : new Date(date));
    } else {
        value = sessionStorage.getItem(key);
    }

    return value;
};