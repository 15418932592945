import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import './index.css';
import App from './App';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { initializeFirebase } from './Utils/pushNotification';

import translation_en from "./translations/en/translation.json";
import translation_fr from "./translations/fr/translation.json";

i18next.init({
  lng: 'fr',
  resources: {
    en: {
      translation: translation_en
    },
    fr: {
      translation: translation_fr
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

initializeFirebase();