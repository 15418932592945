import styled from '@emotion/styled';

const Row = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  flex: 1;
  flex-direction: row;
  padding-bottom: 7px;
`

const FormRow = ({children}) => {
  return (
    <Row>
      {children}
    </Row>
  )
}

export default FormRow;