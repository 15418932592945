const socketMessageType = {
    MESSAGE: "message",
    LOGIN: "login",
    CONVERSATION_ID_UPDATE: "conversationIdUpdate",
    STATUS_UPDATE: "statusUpdate",
    NEW_CONVERSATION: "newConversation",
    MESSAGE_READ: "messageRead",
    PING: "ping"
}

export default socketMessageType;