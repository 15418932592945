import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import FactoryService from "../services/FactoryService";
import Table from "./ui/Table";

const FactoriesList = ({disabledFactoryFilter}) => {
  const [factories, setfactories] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  const columns = [
    { field: 'name', headerName: t('settings.FactoryTable.name'), flex: 1},
    { field: 'location', headerName: t('settings.FactoryTable.location'), flex: 1 },
    { field: 'phoneNumber', headerName: t('settings.FactoryTable.phone'), flex: 1 },
    { field: 'specialisation', headerName: t('settings.FactoryTable.specialisation'), flex: 1 }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const factoryService = new FactoryService();
    const factoriesData = await factoryService.getFactories();

    setfactories(factoriesData);
  }

  const selectFactory = (factory) => {
    history.push('factory/'+factory.id)
  }

  if(!factories || factories.length === 0) return <></>;

  const getFactories = () => {
    if(!disabledFactoryFilter){
      return factories.filter(factory => factory.isActive);
    } else {
      return factories;
    }
  }

  return (
    <Table columns={columns} data={getFactories()} onRowClick={selectFactory} activateColorStyling={true} />
  )
}

export default FactoriesList;