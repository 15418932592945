import { ChromePicker } from 'react-color';

const ColorPicker = ({onChange, value, bindOn}) => {
  const handleColorChange = (color) => {
    onChange(color.hex, bindOn);
  }

  return (
    <ChromePicker color={value ?? '#ffffff'} onChange={handleColorChange}  />
  )
}

export default ColorPicker;