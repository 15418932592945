import styled from '@emotion/styled';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";
import Button from "../components/ui/Button";
import AlertStep from "./AlertStep";

const StepWrapper = styled.div`
  @media (min-width: 600px) {
    grid-template-columns: auto auto;
  }
  padding-top: 10px;
  display: grid;
  grid-gap: 5px;
`

const AddRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 7px;
`

const AlertStepsWrapper = ({settings, setSettings, saveSettings}) => {
  const { t } = useTranslation();
  const getSteps = () => {
    const steps = settings?.find(s => s.type === "alert_steps")?.setting || [];

    return steps.length === 0 ? [] : steps;
  }

  const handleStepUpdate = (index, updatedStep) => {
    const updatedSettings = [...settings];
    let steps = updatedSettings?.find(s => s.type === "alert_steps")?.setting || [];

    if(!steps[index]) return;

    steps[index] = updatedStep;
    updatedSettings.forEach(s => {
      if(s.type === "alert_steps") {
        s.setting = steps;
      }
    })

    setSettings(updatedSettings);
  }

  const handleNewStep = () => {
    const steps = getSteps();
    const newStep = {
      "callOption": "0",
      "step": steps.length + 1,
    }

    steps.push(newStep);

    const updatedSettings = [...settings];
    updatedSettings.forEach(s => {
      if(s.type === "alert_steps") {
        s.setting = steps;
      }
    })

    setSettings(updatedSettings);
  }

  const handleDeleteStep = (index) => {
    let updatedSettings = [...settings];
    updatedSettings.forEach(s => {
      if(s.type === "alert_steps") {
        s.setting.splice(index, 1);
      }
    })

    setSettings(updatedSettings);
  }

  return (
    <>
      <StepWrapper>
        {getSteps().map((s, i) => (
          <AlertStep key={i} step={s} index={i} handleStepUpdate={handleStepUpdate} handleDeleteStep={handleDeleteStep} />
        ))}
      </StepWrapper>
      <AddRow>
        <Button textContent={t('settings.settingSteps.save')} action={() => saveSettings()} />
        <Button textContent={<>&nbsp;<FontAwesomeIcon icon={faPlus} />&nbsp;</>} action={() => handleNewStep()} squared />
      </AddRow>
    </>
  )
}

export default AlertStepsWrapper;