import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCommentDots, faPhoneAlt, faCarSide } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"

const ConversationStatusSwitch = ({status}) => {
  const getStatusIcon = () => {
    const latestStatus = status?.sort((a, b) => a.status > b.status ? 1 : -1)[status.length - 1];

    switch (latestStatus?.status) {
      case 0:
        return faEnvelope;
      case 1:
        return faCommentDots;
      case 2:
        return faPhoneAlt;
      case 3:
        return faCarSide;
      case 4:
        return faCheckCircle;
    
      default:
        return faEnvelope;
    }
  };

  return (
      <FontAwesomeIcon icon={getStatusIcon()} />
    )
}

export default ConversationStatusSwitch;