import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';


const CheckboxMui = ({label, value, onChange, color}) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement="start"
      control={
        <Checkbox
          checked={value}
          onChange={onChange}
          sx={{
            color: color,
            '&.Mui-checked': {
              color: color,
            }
          }}
        />
      }
    />
  )
}

export default CheckboxMui;