import Button from '../components/ui/Button';
import React from "react";
import styled from "@emotion/styled";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  @media (max-width: 1007px) {
    width: 80%;
  }
  width: 40%;
  position: relative;
  top: 5vh;
  height: 50vh;
  text-align: center;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const Important = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: red;
  margin: 20px 0;
`

const BoldMsg = styled.span`
  font-weight: bold;
`

const TextDiv = styled.div`
  margin: 20px 0;
`

const ResolvedCallForm = ({from, date, setLastConversationData}) => {
    const { t } = useTranslation();

    const handleOnClick = () => {
        setLastConversationData(null);
    };

    return (
        <>
            <Wrapper>
                <Title>{t("chat.newForm.welcomeMsg")} <BoldMsg style={{color: "red"}}>{t("chat.newForm.welcomeEmergency")}</BoldMsg> {t("chat.newForm.welcomeMsgExt")}</Title>
                <Important>*** IMPORTANT ***</Important>
                <TextDiv>{t("chat.resolvedForm.resolvedMsg1")} <BoldMsg>{t("chat.resolvedForm.resolved")}</BoldMsg> {t("chat.resolvedForm.from")} {from} {t("chat.resolvedForm.when")} {date}</TextDiv>
                <TextDiv>{t("chat.resolvedForm.clickNewMsg")} <BoldMsg>{t("chat.resolvedForm.clickNew")}</BoldMsg></TextDiv>
                <Button textContent={t("chat.resolvedForm.clickNew")} action={handleOnClick}></Button>
            </Wrapper>
        </>
    );
};

export default ResolvedCallForm