import React, {useEffect, useState} from "react";
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import {getToken, userHasPermission} from "../Utils/Commons";
import { useHistory } from "react-router-dom";
import AccordionWrapper from "../components/ui/AccordionWrapper";
import ClientsList from "../components/ClientsList";
import FactoriesList from "../components/FactoriesList";
import Button from "../components/ui/Button";
import {faPlus, faUsers, faUsersSlash} from "@fortawesome/free-solid-svg-icons";
import { faFileExcel } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Settings from "../components/Settings";

const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 5px;
`

const AddRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 7px;
`

const SettingsPage = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [disabledContactFilter, setDisabledContactFilter] = useState(false);
  const [disabledFactoryFilter, setDisabledFactoryFilter] = useState(false);

  useEffect(() => {
    // Prevent user to access page
    if(!userHasPermission("canSeeConfig")) {
      history.push('/');
    }
  }, []);

  const handleNewClient = () => {
    history.push('/user');
  }

  const handleNewFactory = () => {
    history.push('/factory');
  }

  const handleExportClients = () => {
      window.open(`${process.env.REACT_APP_API_URL}api/Client.php?jwt=${getToken()}&mode=exportClients`);
  };

  const handleExportFactories = () => {
      window.open(`${process.env.REACT_APP_API_URL}api/Factory.php?jwt=${getToken()}&mode=exportFactories`);
  };

  return (
    <>
      <Wrapper>
        <AccordionWrapper title={t('settings.clients')}>
          <ClientsList disabledContactFilter={disabledContactFilter}/>
          <AddRow>
            <Button textContent={<>&nbsp;<FontAwesomeIcon icon={disabledContactFilter ? faUsers : faUsersSlash} />&nbsp;</>}
                    action={() => setDisabledContactFilter(!disabledContactFilter)} squared color={disabledContactFilter ? "red": null} />
            <Button textContent={<>&nbsp;<FontAwesomeIcon icon={faFileExcel} />&nbsp;</>} action={() => handleExportClients()} squared />
            <Button textContent={<>&nbsp;<FontAwesomeIcon icon={faPlus} />&nbsp;</>} action={() => handleNewClient()} squared />
          </AddRow>
        </AccordionWrapper>
      </Wrapper>
      <Wrapper>
        <AccordionWrapper title={t('settings.factories')}>
        <FactoriesList disabledFactoryFilter={disabledFactoryFilter}/>
          <AddRow>
            <Button textContent={<>&nbsp;<FontAwesomeIcon icon={disabledFactoryFilter ? faUsers : faUsersSlash} />&nbsp;</>}
                    action={() => setDisabledFactoryFilter(!disabledFactoryFilter)} squared color={disabledFactoryFilter ? "red": null} />
            <Button textContent={<>&nbsp;<FontAwesomeIcon icon={faFileExcel} />&nbsp;</>} action={() => handleExportFactories()} squared/>
            <Button textContent={<>&nbsp;<FontAwesomeIcon icon={faPlus} />&nbsp;</>} action={() => handleNewFactory()} squared />
          </AddRow>
        </AccordionWrapper>
      </Wrapper>
      <Wrapper>
        <AccordionWrapper title={t('settings.parameters')} >
          <Settings />
        </AccordionWrapper>
      </Wrapper>
    </>
  )
}

export default SettingsPage;