import axios from 'axios';

class AuthentificationRepository {
  login = async (username, password, fcmToken) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };
    var res = null;

    await axios.post(
      `${process.env.REACT_APP_API_URL}api/Login.php`,
      { username, password, fcmToken },
      { headers }
    )
    .then(response => {
      res = response.data;

    }).catch(error => {
      // Handle error back to the service > login page
          res = error.response.status;
    });

    return res;
  }

  getClientId = async (token) => {
    const headers = {
      'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
    };

    var userId = null;
    await axios.get(
      `${process.env.REACT_APP_API_URL}api/Validate_token.php?jwt=${token}`,
      { headers }
    )
    .then(response => {
      userId = response.data.decodedToken.data.id;

    }).catch(error => {
      // Handle error back to the service > login page
    });

    return userId;
  }
}

export default AuthentificationRepository;