import Select from "./ui/Select";


const TimeZoneSelection = ({placeholder, value, bindOn, onChange}) => {
  const timeZoneData = [
    {
      'id': '-03:30',
      'name': 'Newfoundland (UTC-3)'
    },
    {
      'id': '-04:00',
      'name': 'Atlantic (UTC-4)'
    },
    {
      'id': '-05:00',
      'name': 'Eastern (UTC-5)'
    },
    {
      'id': '-06:00',
      'name': 'Central (UTC-6)'
    },
    {
      'id': '-07:00',
      'name': 'Mountain (UTC-7)'
    },
    {
      'id': '-08:00',
      'name': 'Pacific (UTC-8)'
    },
  ];

  return (
    <Select placeholder={placeholder} value={value} data={timeZoneData} bindOn={bindOn} onChange={onChange} />
  )
}

export default TimeZoneSelection;