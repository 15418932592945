import SupportTimeSlotRepository from "../repositories/SupportTimeSlotRepository";

class SupportTimeSlotService {
  getTimeSlots = async (specialisation) => {
    if(specialisation == null) return null;

    const timeSlotRepo = new SupportTimeSlotRepository();
    const supportTimeSlots = await timeSlotRepo.getTimeSlots(specialisation);
    
    var supportTimeSlotsdata = {
      'supportTimeSlots': JSON.parse(supportTimeSlots.supportTimeSlots),
      'users': JSON.parse(supportTimeSlots.users)
    }

    return supportTimeSlotsdata;
  };

  saveTimeSlots = async(specialisation, range) => {
    if(specialisation == null || range == null) return null;

    const timeSlotRepo = new SupportTimeSlotRepository();
    return await timeSlotRepo.saveTimeSlots(specialisation, range);
  }

  updateUserRange = async(rangeId, userId) => {
    if(rangeId == null || userId == null) return null;

    const timeSlotRepo = new SupportTimeSlotRepository();
    return await timeSlotRepo.updateUserRange(rangeId, userId);
  }

  deleteTimeSlot = async(rangeId) => {
    if(rangeId == null) return;

    const timeSlotRepo = new SupportTimeSlotRepository();
    await timeSlotRepo.deleteTimeSlot(rangeId);
  }

  getCurrentSupportTeamMember = async(factoryId) => {
    if(!factoryId) return;

    const timeSlotRepo = new SupportTimeSlotRepository();
    return await timeSlotRepo.getCurrentSupportTeamMember(factoryId);
  }

  getCurrentActiveSupportTeamMembers = async() => {
    const timeSlotRepo = new SupportTimeSlotRepository();
    return await timeSlotRepo.getCurrentActiveSupportTeamMembers();
  }

  makeTestCall = async (specialisationType) => {
    const supportTimeSlotRepository = new SupportTimeSlotRepository();
    await supportTimeSlotRepository.makeTestCall(specialisationType);
  }

  makePersonalTestCall = async (userId) => {
    const supportTimeSlotRepository = new SupportTimeSlotRepository();
    await supportTimeSlotRepository.makePersonalTestCall(userId);
  }

}

export default SupportTimeSlotService;