import SettingRepository from "../repositories/SettingRepository";

class SettingService {
  getSettings = async () => {
    const repo = new SettingRepository();
    var settings = await repo.getSettings();
    settings = JSON.parse(settings);

    // Parse steps settings
    settings && settings.forEach(s => {
      if(s.type === "alert_steps") {
        s.setting = JSON.parse(s.setting);
      }
    })

    return settings;
  }

  saveSettings = async (settings) => {
    const repo = new SettingRepository();
    return await repo.saveSettings(settings);
  }
}

export default SettingService;