import ConversationStatusRepository from "../repositories/ConversationStatusRepository";

class ConversationStatusService {
  updateConversationStatus = async (status, conversationId) => {
    if (!conversationId) return;

    const conversationStatusRepo = new ConversationStatusRepository();
    await conversationStatusRepo.updateConversationStatus(status, conversationId);
  };

  getConversationStatus = async (conversationId) => {
    if (!conversationId) return;

    const conversationStatusRepo = new ConversationStatusRepository();
    const status = await conversationStatusRepo.getConversationStatus(conversationId);
    
    return status;
  };
}

export default ConversationStatusService;