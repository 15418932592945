import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom";
import {useTranslation} from "react-i18next";
import ClientService from "../services/ClientService";
import Table from "./ui/Table";
import { saveDataInStorage, getValueFromStorage } from "../Utils/Commons";

const currentPageKeyFilter = "clientList.";

const ClientsList = ({disabledContactFilter}) => {
  const [clients, setClients] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();
  const [factoryNameFilter, setFactoryNameFilter] = useState(getValueFromStorage(currentPageKeyFilter + "factoryNameFilter"));

  const columns = [
    { field: 'factoryName', headerName: t('settings.clientTable.factoryname'), flex: 1 },
    { field: 'firstname', headerName: t('settings.clientTable.firstname'), flex: 1 },
    { field: 'lastname', headerName: t('settings.clientTable.lastname'), flex: 1 },
    { field: 'email', headerName: t('settings.clientTable.email'), flex: 1 },
    { field: 'phoneNumber', headerName: t('settings.clientTable.phone'), flex: 1 }
  ];

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const clientService = new ClientService();
    const clientsData = await clientService.getClients();

    setClients(clientsData);
  };

  const selectClient = (client) => {
    history.push('user/'+client.id)
  }

  if(!clients || clients.length === 0) return <></>;

  const getContacts = () => {
    if(!disabledContactFilter){
      return clients.filter((client) => {
        if (factoryNameFilter !== null) {
          return client.isActive && client.factoryName.toLowerCase().includes(factoryNameFilter);
        } else {
          return client.isActive
        }
      });
    } else {
      return clients;
    }
  }

  const onFilterChange = (filter) => {
    if(filter.items.length === 0 || (filter.items.length > 0 && (filter.items[0].value?.length === 0 || filter.items[0].value === undefined))) {
      saveDataInStorage(currentPageKeyFilter + "factoryNameFilter", "");
      setFactoryNameFilter("");
      return;
    }
    if((filter.items[0]?.columnField !== undefined && filter.items[0].value !== undefined) && filter.items[0]?.columnField === "factoryName") {
      saveDataInStorage(currentPageKeyFilter + "factoryNameFilter", filter.items[0].value.toLowerCase());
      setFactoryNameFilter(filter.items[0].value.toLowerCase());
    }
  }

  return (
    <Table columns={columns} data={getContacts()} onRowClick={selectClient} activateColorStyling={true} onFilterChange={onFilterChange} />
  )
}

export default ClientsList;