import styled from '@emotion/styled';

const Backdrop = styled.div`
  position: absolute;
  background: #66666691;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
`

const ModalBackdrop = ({setStateValue}) => {
  return (
    <Backdrop onClick={() => setStateValue && setStateValue(false)} />
  )
}

export default ModalBackdrop;