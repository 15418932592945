import { getToken } from "../Utils/Commons";
import axios from "axios";


class SettingRepository {
    getSettings = async () => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };
  
      var settings = null;
      var token = getToken();
      await axios.get(
        `${process.env.REACT_APP_API_URL}api/Setting.php?jwt=${token}`,
        { headers }
      )
      .then(response => {
        settings = response.data.settings;
      }).catch(error => {
        // Handle error back to the service > login page
      });
  
      return settings;
    }

    saveSettings = async (settings) => {
      const headers = {
        'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
      };

      var res = null;
      var token = getToken();
      await axios.post(
        `${process.env.REACT_APP_API_URL}api/Setting.php`,
        { headers, jwt: token, settings: JSON.stringify(settings) }
      )
      .then(response => {
        res = {
          success: true
        }
      }).catch(error => {
        res = {
          success: false,
          message: error.response.data.message
        }
      });

      return res;
    }
  }
  
  export default SettingRepository;