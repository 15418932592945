import styled from "@emotion/styled";
import { isMobile } from "react-device-detect";

const Wrapper = styled.div`
    padding: 20px;
`
const AgreementContainer = styled.div(
    {}, props => (
        {
            padding: (isMobile) ? "2% 5%" : "2% 15%"
        }
    )
);
const AgreementSection = styled.section`
    margin: 50px auto;
`

const AgreementPage = () => {

    const handleShowPDF = () => {
        window.open(`/EntenteRenumeration.pdf`, '_blank');
    };

    return (
        <>
            <Wrapper>
                <h1 style={{textAlign: 'center'}}>SOLU360.ca – ENTENTE de RÉMUNÉRATION</h1>
                <AgreementContainer>
                    <div style={{textAlign: 'left'}}>
                        <button onClick={handleShowPDF}>Voir la version PDF</button>
                    </div>
                    <AgreementSection>
                        <h3>Prime de disponibilité :</h3>
                        <p>
                            • Chaque intervenant (SCADA & PLC) est admissible à une prime de disponibilité associée
                            aux accords contractuels avec les clients participants au programme de Support VIP.
                        </p>
                    </AgreementSection>
                    <AgreementSection>
                        <h3>Prise en charge des appels et coordination :</h3>
                        <p>
                            • Si la personne se limite à coordonner et de transférer l'appel, la prime hebdomadaire
                            sera sa seule rémunération.
                        </p>
                    </AgreementSection>
                    <AgreementSection>
                        <h3>Résolution du problème par l'Intervenant Principal :</h3>
                        <p>
                            • 100 % des heures minimales (2 heures pendant nos heures d'ouverture / 4 heures
                            pendant nos heures de fermeture) seront attribuées à l'Intervenant Principal.
                        </p>
                    </AgreementSection>
                    <AgreementSection>
                        <h3>
                            Résolution du problème par l'Intervenant Principal (IP) avec la participation d'un
                            Intervenant en Support (IS) ou d'une autre discipline (AD) :
                        </h3>
                        <p>
                            • Les heures minimales (2 heures pendant nos heures d'ouverture (HO) / 4 heures pendant
                            nos heures de fermeture (HF)) seront réparties de manière égale (50%/50%) entre
                            l'Intervenant Principal et l'Intervenant en Support ou d'une autre discipline.
                        </p>
                        <div style={{paddingLeft: '5%'}}>
                            <h5>APPEL / SUPPORT MULTIPLE & MULTI DISCIPLINAIRE</h5>
                            <div>Répartition des heures (Minimum charge)</div>
                            <ul style={{marginTop: '0'}}>
                                <li>Appel H-O (IP2hrs+IS-AD1hrs)</li>
                                <li>Appel H-F (IP4hrs+IS-AD2hrs)</li>
                            </ul>
                        </div>
                    </AgreementSection>
                    <AgreementSection>
                        <p>
                            Veuillez noter que si les heures minimales sont dépassées pendant l'intervention,
                            toutes les heures supplémentaires seront facturés en fonction du créneau horaire concerné.
                        </p>
                    </AgreementSection>
                </AgreementContainer>
            </Wrapper>
        </>
    );
}

export default AgreementPage;