import React, { useEffect, useState } from "react";
import ConversationService from "../services/ConversationService";
import styled from '@emotion/styled';
import DatePicker from 'react-date-picker'
import {useTranslation} from "react-i18next";
import FactoryService from "../services/FactoryService";
import ClientService from "../services/ClientService";
import Table from "../components/ui/Table";
import {useHistory} from "react-router-dom";
import { saveDataInStorage, getValueFromStorage } from "../Utils/Commons";
import RapportInterventionService from "../services/RapportInterventionService";

const currentPageKeyFilter = "interventionReportHistory.";

const Wrapper = styled.div`
  padding-top: 10px;
  overflow: auto;
  height: 95vh;
`

const VerticalCenterFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const Filter = styled.div`
  margin: 5px;
  width: 300px;
  text-align: center;
`

const ConversationListHistoryPage = () => {
    const [interventions, setInterventions] = useState([]);
    const [dateFrom, setDateFrom] = useState(getValueFromStorage(currentPageKeyFilter + "dateFrom"));
    const [dateTo, setDateTo] = useState(getValueFromStorage(currentPageKeyFilter + "dateTo"));

    const history = useHistory();
    const { t } = useTranslation();

    const codes = [
        {
            id: 1,
            label: `${t(`rapportIntervention.codes.critical.severity`)}: ${t(`rapportIntervention.codes.critical.description`)}`,
            severity: `${t(`rapportIntervention.codes.critical.severity`)}`,
            value: "critical"
        },
        {
            id: 2,
            label: `${t(`rapportIntervention.codes.high.severity`)}: ${t(`rapportIntervention.codes.high.description`)}`,
            severity: `${t(`rapportIntervention.codes.high.severity`)}`,
            value: "high"
        },
        {
            id: 3,
            label: `${t(`rapportIntervention.codes.medium.severity`)}: ${t(`rapportIntervention.codes.medium.description`)}`,
            severity: `${t(`rapportIntervention.codes.medium.severity`)}`,
            value: "medium"
        },
        {
            id: 4,
            label: `${t(`rapportIntervention.codes.low.severity`)}: ${t(`rapportIntervention.codes.low.description`)}`,
            severity: `${t(`rapportIntervention.codes.low.severity`)}`,
            value: "low"
        }
    ];

    const columns = [
        {
            field: 'factoryName', headerName: t('rapportInterventionHistory.headers.factory'), flex: 1,
            valueGetter: (obj) => obj.row.factoryName
        },
        {
            field: 'clientName', headerName: t('rapportInterventionHistory.headers.clientName'), flex: 1,
            valueGetter: (obj) => obj.row.clientName
        },
        {
            field: 'severity', headerName: t('rapportInterventionHistory.headers.severity'), flex: 1,
            valueGetter: (obj) => codes.find(code => code.value === obj.row.code).severity
        },
        { field: 'conversationCreatedAt', headerName: t('rapportInterventionHistory.headers.callDate'), flex: 1 },
        {
            field: 'answererName', headerName: t('rapportInterventionHistory.headers.answererName'), flex: 1,
            valueGetter: (obj) => obj.row.answererName
        },
        {
            field: 'techName', headerName: t('rapportInterventionHistory.headers.techRapportName'), flex: 1,
            valueGetter: (obj) => obj.row.techName
        },
        {
            field: 'dateRapport', headerName: t('rapportInterventionHistory.headers.rapportDate'), flex: 1,
            valueGetter: (obj) => obj.row.dateRapport
        }
    ];

    useEffect(()=>{
        fetchData();
    },[dateFrom, dateTo])

    const fetchData = async () => {
        const dateFromUtc = Date.UTC(dateFrom.getUTCFullYear(), dateFrom.getUTCMonth(), dateFrom.getUTCDate(), 0, 0, 0);
        const dateToUtc = Date.UTC(dateTo.getUTCFullYear(), dateTo.getUTCMonth(), dateTo.getUTCDate(), 23, 59, 59);

        const rapportInterventionService = new RapportInterventionService();
        const allRapports = await rapportInterventionService.getAllRapports([new Date(dateFromUtc), new Date(dateToUtc)])

        setInterventions(allRapports);
    };

    const handleClick = (id) => {
        history.push(`/rapportInterventionView/${id}`);
    }

    return (
        <Wrapper>
            <div className={"filterContainer"}>
                <VerticalCenterFilters>
                    <Filter className={"inputFilter"} style={{zIndex: 2}}>
                        <label style={{marginRight: "5px", textAlign: "bottom"}}>{t('filters.dateFrom') + ":"}</label>
                        <DatePicker onChange={(value) => { saveDataInStorage(currentPageKeyFilter + "dateFrom", value.getTime()); setDateFrom(value) }}
                                    value={dateFrom} format={"y-MM-dd"} clearIcon={null} maxDate={dateTo}/>
                    </Filter>
                    <Filter className={"inputFilter"} style={{zIndex: 2}}>
                        <label style={{marginRight: "5px", textAlign: "bottom"}}>{t('filters.dateTo') + ":"}</label>
                        <DatePicker onChange={(value) => { saveDataInStorage(currentPageKeyFilter + "dateTo", value.getTime()); setDateTo(value) }}
                                    value={dateTo} format={"y-MM-dd"} clearIcon={null} minDate={dateFrom}/>
                    </Filter>
                </VerticalCenterFilters>
            </div>

            <div style={{width: "1200px", height: "90%", margin: "auto", marginTop: "25px"}}>
                <Table columns={columns} data={interventions} onRowClick={(event) => handleClick(event.row.conversationId)} />
            </div>
        </Wrapper>
    )
};

export default ConversationListHistoryPage;