import AuthentificationRepository from "../repositories/AuthentificationRepository";
import { setUserSession, getToken } from '../Utils/Commons';
import { getUserToken } from "../Utils/pushNotification";

class AuthentificationService {
  login = async (username, password) => {
    if (!username || !password) return;

    //Get fcm token
    const fcmToken = await getUserToken();

    const authRepo = new AuthentificationRepository();
    const response = await authRepo.login(username, password, fcmToken);

    if (response?.jwt != null) {
      setUserSession(response.jwt, response.user);
    }

    return response
  }

  getClientId = async () => {
    const token = getToken();

    if(!token) return;

    const authRepo = new AuthentificationRepository();
    const userId = await authRepo.getClientId(token);

    return userId;
  }
}

export default AuthentificationService;