import styled from '@emotion/styled';

const Section = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
`

const FormSection = ({children}) => {
  return (
    <Section>
      {children}
    </Section>
  )
}

export default FormSection;