import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCommentDots, faPhoneAlt, faCarSide } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons"
import moment from "moment";
import styled from '@emotion/styled';
import statusEnum from "../enums/statusEnum";
import CancelAlertButton from "./CancelAlertButton";

const Wrapper = styled.div`
  height: 43px;
  padding: 5px 5px;
  display: flex;
  justify-content: space-around;
  &>div:after {
    content: "";
    position: absolute;
    width: 100%;
    border-bottom: 1px dotted black;
    top: 54%;
    left: -50%;
    z-index : -1;
  }
  &>div:first-of-type:after {
    content: none;
  }
`

const StatusOption = styled.div`
  position: relative;
  width: 100%;
`

const StepIcon = styled.div`
  display: flex;
  justify-content: center;
  padding: 2px;
`

const StepDot = styled.div(
  {
    height: "5px",
    width: "5px",
    borderRadius: "50%",
    margin: "auto"
  }, props => ({backgroundColor: props.color})
)

const StepDate = styled.div`
  font-size: 8px;
  font-weight: 800;
  padding: 3px;
  text-align: center;
`

const StatusBanner = ({statusList, hasActiveAlerts, secondaryColor, setCurrentALertsFalse}) => {
  const [statusOptions, setStatusOptions] = useState([]);
  
  const isActive = (s) => {
    const hasStatus = statusList.find(x => x.status === s);
    return !!hasStatus;
  }

  useEffect(() => {
    const options = [
      {
        type: faEnvelope,
        active: isActive(statusEnum.CREATED),
        status: statusList.find(s => s.status === statusEnum.CREATED)
      },
      {
        type: faCommentDots,
        active: isActive(statusEnum.RESPONDED),
        status: statusList.find(s => s.status === statusEnum.RESPONDED)
      },
      {
        type: faPhoneAlt,
        active: isActive(statusEnum.CALLED),
        status: statusList.find(s => s.status === statusEnum.CALLED)
      },
      {
        type: faCarSide,
        active: isActive(statusEnum.MOVED),
        status: statusList.find(s => s.status === statusEnum.MOVED)
      },
      {
        type: faCheckCircle,
        active: isActive(statusEnum.RESOLVED),
        status: statusList.find(s => s.status === statusEnum.RESOLVED)
      }
    ];

    setStatusOptions(options);
  }, [statusList]);

  return (
    <>
      <Wrapper style={{borderBottom: `2px solid ${secondaryColor}`}}>
        {statusOptions.map((so, index) => (
          <StatusOption key={index}>
            <StepIcon><FontAwesomeIcon icon={so.type} color={so.active ? "black" : secondaryColor} key={index} /></StepIcon>
            <StepDot color={so.active ? "black" : secondaryColor} />
            {so.status && <StepDate>{moment(so.status.createdAt).format("HH:mm")}</StepDate>}
          </StatusOption>
        ))}
        {hasActiveAlerts && <CancelAlertButton setCurrentALertsFalse={setCurrentALertsFalse} />}
      </Wrapper>
      
    </>
  );
}

export default StatusBanner;