import InterventionReportPDF from "../components/InterventionReportPDF";
import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ConversationService from "../services/ConversationService";
import ConversationStatusService from "../services/conversationStatusService";
import ClientService from "../services/ClientService";
import {useTranslation} from "react-i18next";
import {PDFViewer} from "@react-pdf/renderer";
import {getUserSecondaryColor} from "../Utils/Commons";
import MenuOption from "../components/MenuOption";
import {faBook} from "@fortawesome/free-solid-svg-icons";

const InterventionReportPage = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const [conversationId] = useState(useParams().conversationId || 0);
    const [conversation, setConversation] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [factoryClient, setFactoryClient] = useState([]);
    const [technicien, setTechnicien] = useState([]);
    const [questions, setQuestions] = useState([]);
    const [rapportIntervention, setRapportIntervention] = useState([])

    useEffect(() => {
        if (conversationId !== 0) {
            fetchData();
        }
    }, [conversationId]);

    const fetchData = async() => {
        // Get the messages history for the conversation and update the messages state.
        const conversationService = new ConversationService();
        var conversationData = await conversationService.getConversation(conversationId);

        if(conversationData) {
            var {messages: messagesData, ...conversationData} = conversationData;

            setConversation(conversationData);
            setChatMessages(messagesData);

            setFactoryClient(conversationData.user.factory);
            setRapportIntervention(conversationData.rapportIntervention)
        }

        const questions = [
            {
                "index": 0,
                "description": "rapportIntervention.questions.q1",
                "value": conversationData.rapportIntervention.question1
            },
            {
                "index": 1,
                "description": "rapportIntervention.questions.q2",
                "value": conversationData.rapportIntervention.question2
            },
            {
                "index": 2,
                "description": "rapportIntervention.questions.q3",
                "value": conversationData.rapportIntervention.question3
            },
            {
                "index": 3,
                "description": "rapportIntervention.questions.q4",
                "value": conversationData.rapportIntervention.question4
            },
            {
                "index": 4,
                "description": "rapportIntervention.questions.q5",
                "value": conversationData.rapportIntervention.question5
            }
        ];

        setQuestions(questions);
        fetchConversationStatus();
        fetchTechnicienInfo(conversationData.rapportIntervention);
    }

    const fetchConversationStatus = async() => {
        const conversationStatusService = new ConversationStatusService();
        const status = await conversationStatusService.getConversationStatus(conversationId);

        const updatedConversation = {...conversation};
        updatedConversation.status = status;
        setConversation(updatedConversation);
    }

    const fetchTechnicienInfo = async (rapportInterventionData) => {
        const clientService = new ClientService();
        const technicienData = await clientService.getClient(rapportInterventionData.technicienId);

        if (technicienData) {
            setTechnicien(technicienData);
        }
    };

    const handleRIHistory = () => {
        history.push('/interventionReportHistory');
    }

    return (
        <>
            <div style={{backgroundColor: getUserSecondaryColor(), display: "flex", justifyContent: "center"}} onClick={handleRIHistory}>
                <MenuOption icon={faBook} label={t('rapportInterventionHistory.returnHistoRapportIntervention')} changeStatus={() => {}}/>
            </div>

            <PDFViewer style={{width:"99%", height: "92vh"}}>
                <InterventionReportPDF t={t} rapportIntervention={rapportIntervention}
                                       questions={questions} conversation={conversation} chatMessages={chatMessages}
                                       factoryClient={factoryClient} technicien={technicien}
                />
            </PDFViewer>
        </>
    )
}

export default InterventionReportPage;