import { getToken } from "../Utils/Commons";
import axios from "axios";

class LogTestConnectionRepository {

    getAllLogs = async () => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let rapport = null;
        const token = getToken();
        await axios.get(
            `${process.env.REACT_APP_API_URL}api/LogTestConnection.php?jwt=${token}&mode=getAllLogs`,
            { headers }
        )
            .then(response => {
                rapport = JSON.parse(response.data.allLogs);
            }).catch(error => {
                // console.log(error);
            });

        return rapport;
    };

    getAllHistoryLogs = async (arrayFilter) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let rapport = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/LogTestConnection.php`,
            { jwt: token, mode: "getAllHistoryLogs", arrayFilter: arrayFilter},
            { headers }
        )
            .then(response => {
                rapport = JSON.parse(response.data.allLogs);
            }).catch(error => {
                // console.log(error);
            });

        return rapport;
    }

    saveLog = async (log) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let res = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/LogTestConnection.php`,
            { headers, jwt: token, logTestConnection: JSON.stringify(log), mode: "saveLog" }
        ).then(() => {
            res = {
                success: true
            }
        }).catch(error => {
            res = {
                success: false,
                message: error.response.data.message
            }
        });

        return res;
    };

    deleteLog = async (log) => {
        const headers = {
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let res = null;
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/LogTestConnection.php`,
            { headers, jwt: token, logTestConnection: JSON.stringify(log), mode: "deleteLog" }
        ).then(() => {
            res = {
                success: true
            }
        }).catch(error => {
            res = {
                success: false,
                message: error.response.data.message
            }
        });

        return res;
    };
}

export default LogTestConnectionRepository