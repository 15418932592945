import React, { useEffect, useState } from "react";
import { getUser, getUserColor, getUserSecondaryColor} from "../Utils/Commons"
import {useTranslation} from "react-i18next";

import SupportTimeSlotService from "../services/SupportTimeSlotService";
import ModalWrapper from "./ui/ModalWrapper";
import ModalBody from "./ModalBody";

const ConfirmationChatFormModal = ({handleRedirection}) => {
  const { t } = useTranslation();
  const primaryColor = getUserColor();
  const secondaryColor = getUserSecondaryColor();
  const user = getUser();
  const [supportMember, setSupportMember] = useState(null);

  useEffect(() => {
    fetchData();
  }, [])

  const fetchData = async() => {
    const supportTimeSlotsService = new SupportTimeSlotService();
    const currentSupportMembreName = await supportTimeSlotsService.getCurrentSupportTeamMember(user.factoryId);

    setSupportMember(currentSupportMembreName);
  }

  return (
    <ModalWrapper color={secondaryColor}>
      <ModalBody 
        text={supportMember ? `${supportMember} ${t('chat.newForm.confirmationMsg')}` : `${t('chat.newForm.defaultSupportName')} ${t('chat.newForm.confirmationMsg')}`}
        button1Color={primaryColor}
        button1Action={handleRedirection}
        button1Text="Ok" />
    </ModalWrapper>
  )
}

export default ConfirmationChatFormModal;