import { DataGrid } from '@mui/x-data-grid';

const ROW_HEIGHT = 35;
const HEADER_HEIGHT = 56;
const TABLE_MAX_HEIGHT = (15 * ROW_HEIGHT) + HEADER_HEIGHT // Show minimum of 15 rows

const Table = ({columns, data, onRowClick, activateColorStyling, onFilterChange }) => {
  const tableHeight = () => {
    if (data.length * ROW_HEIGHT < TABLE_MAX_HEIGHT) return ((data.length + 1) * ROW_HEIGHT) + HEADER_HEIGHT;

    return TABLE_MAX_HEIGHT;
  }

  const handleOnFilterChange = (v) => {
    if(onFilterChange !== undefined) {
      onFilterChange(v);
    }
  }

  return (
    <DataGrid className={"rdg-light"} columns={columns} rows={data} style={{ height: tableHeight() }} onRowClick={(v) => onRowClick(v)} hideFooter={true} rowHeight={ROW_HEIGHT}
        getRowClassName={params => (activateColorStyling && !params.row.isActive) ? "disabled-row" : ""} onFilterModelChange={(v) => handleOnFilterChange(v)}
    />
  )
}

export default Table;