import NoteRepository from "../repositories/NoteRepository";

class NoteService {
    addNote = async (noteMessage, conversationId) => {
        if (!conversationId || !noteMessage) return;

        const noteRepo = new NoteRepository();
        await noteRepo.addNote(noteMessage, conversationId);
    };

    getNote = async (conversationId) => {
        if (!conversationId) { return; }

        const noteRepo = new NoteRepository();
        return await noteRepo.getNote(conversationId);
    }

    updateNote = async (noteInfo) => {
        if (!noteInfo) { return; }

        const noteRepo = new NoteRepository();
        await noteRepo.updateNote(noteInfo);
    }

    deleteNote = async (conversationId) => {
        if (!conversationId) { return; }

        const noteRepo = new NoteRepository();
        await noteRepo.deleteNote(conversationId);
    }

}

export default NoteService;