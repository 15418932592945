import React from 'react';
import {Document, Page, Text, StyleSheet, View, Font} from '@react-pdf/renderer';
import MtCorsvaFont from "../assets/fonts/MTCORSVA.TTF";
import moment from "moment";
import statusEnum from "../enums/statusEnum";

Font.register({ family: 'MonoTypeCorsiva', fonts: [{src: MtCorsvaFont }]} );

// Create styles
const styles = StyleSheet.create({
    body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35
    },
    Header: {
        paddingTop: 10,
        position: 'absolute',
        fontSize: 14,
        left: 35,
        right: 35,
        textAlign: 'center',
        marginBottom: 10
    },
    questionDescription: {
        fontSize: 12,
        marginTop: 20,
        marginVertical: 4
    },
    questionReponse: {
        border: "1px solid black",
        borderRadius: 5,
        padding: 8,
        marginVertical: 6,
        fontSize: 10,
        textAlign: 'justify',
        fontFamily: 'Times-Roman',
        overflow: 'hidden'
    },
    TableRow: {
        display: 'flex',
        flexDirection: 'row',
        margin: 0
    },
    TableCell: {
        marginHorizontal: 0,
        fontSize: 10,
        padding: 1,
    },
    TableHeaderCol: {
        display: 'flex',
    },
    Footer: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 35,
        right: 35,
        textAlign: 'center',
        color: 'grey'
    },
    tableDate: {
        width: 135,
        position: "absolute",
        margin: 35,
        right: 0
    },
    signature: {
        marginTop: 10,
        fontSize: 16,
        fontFamily: 'MonoTypeCorsiva'
    }
});

// Create Document Component
const InterventionReportPDF = ({t, rapportIntervention, questions, conversation, chatMessages, factoryClient, technicien}) => {
    const formatDate = (date, format = "YYYY-MM-DD HH:mm") => {
        return moment(date).format(format);
    };

    const getStatusHour = (statusCode) => {
        const date = conversation?.status.find(s => s.status === statusCode)?.createdAt ?? null
        return date !== null ? moment(date).format("YYYY-MM-DD HH:mm") : "";
    };

    const getCodeProjet = () => {
        let reportNumber = rapportIntervention.reportNumber;
        if (reportNumber < 10){ reportNumber = `0${reportNumber}`; }

        return `${factoryClient.codeProjet}-RI-${reportNumber}a`;
    };

    return (
        <Document>
            <Page style={styles.body}>
                <Text style={[styles.Header, {textAlign: "left"}]}>{chatMessages[0]?.user.factoryName}</Text>
                <Text style={styles.Header}>{t('rapportIntervention.nom')}</Text>
                <Text style={[styles.Header, {textAlign: "right", color: "red"}]}>{getCodeProjet()}</Text>

                {/*Tables*/}
                <View wrap={false} style={{width: 200}}>
                    <View style={styles.TableRow}>
                        <TableCell width={"20%"} text={`${t('rapportIntervention.header.caller')}:`}/>
                        <TableCell width={"80%"} text={chatMessages[0]?.user.name}/>
                    </View>
                    <View style={styles.TableRow}>
                        <TableCell width={"20%"} text={""}/>
                        <TableCell width={"80%"} text={chatMessages[0]?.user.factoryName}/>
                    </View>
                    <View style={styles.TableRow}>
                        <TableCell width={"20%"} text={`${t('rapportIntervention.header.call')}:`}/>
                        <TableCell width={"80%"} text={getStatusHour(statusEnum.CREATED)}/>
                    </View>
                </View>

                <View wrap={false} style={styles.tableDate}>
                    <View style={styles.TableRow}>
                        <TableCell width={"40%"} text={`${t('rapportIntervention.header.answer')}:`}/>
                        <TableCell width={"60%"} text={getStatusHour(statusEnum.RESPONDED)}/>
                    </View>
                    <View style={styles.TableRow}>
                        <TableCell width={"40%"} text={`${t('rapportIntervention.header.resolved')}:`}/>
                        <TableCell width={"60%"} text={getStatusHour(statusEnum.RESOLVED)}/>
                    </View>
                    <View style={styles.TableRow}>
                        <TableCell width={"40%"} text={`${t('rapportIntervention.header.report')}:`}/>
                        <TableCell width={"60%"} text={formatDate(rapportIntervention.dateRapport)}/>
                    </View>
                </View>

                {/*Questions*/}
                {questions?.map((question, index) => (
                    <View wrap={false} style={{marginTop: 15}} key={index}>
                        {index === 0 &&
                            <CodeBlock code={rapportIntervention.code} t={t}></CodeBlock>
                        }
                        <Text style={styles.questionDescription}>
                            {t(question.description)}
                        </Text>
                        <Text style={styles.questionReponse}>
                            {question.value}
                        </Text>
                    </View>
                ))}

                <View wrap={false} style={{marginTop: 15, fontSize: 12}}>
                    <Text style={{textAlign: "left"}}>{t('rapportIntervention.footer.message')}</Text>
                    <Text style={[styles.signature, {textAlign: "left"}]}>{technicien.name}</Text>
                    <Text style={{textAlign: "left"}}>{technicien.factoryName}</Text>
                </View>

                {/*Footer*/}
                <Text style={[styles.Footer, {textAlign: "left"}]} render={() => (`${getCodeProjet()}`)} fixed/>
                <Text style={styles.Footer} render={({pageNumber, totalPages}) => (
                    `${pageNumber} / ${totalPages}`
                )} fixed/>
                <Text style={[styles.Footer, {textAlign: "right"}]} render={() => (`${formatDate(rapportIntervention.dateRapport, "YYYY-MM-DD")}`)} fixed/>
            </Page>
        </Document>
    );
};

const TableCell = ({width = "100%", text}) => (
    <View style={[styles.TableHeaderCol, {width: width}]}>
        <Text style={styles.TableCell}>{text}</Text>
    </View>
);

const CodeBlock = ({code, t}) => (
    <View wrap={false}>
        <Text style={styles.questionDescription}>Code:</Text>

        <View style={[styles.TableRow, {marginLeft: 35, marginBottom: 20}]}>
            <TableCell width={"10%"} text={`${t(`rapportIntervention.codes.${code}.severity`)}:`}/>
            <TableCell width={"90%"} text={`${t(`rapportIntervention.codes.${code}.description`)}`}/>
        </View>
    </View>
);

export default InterventionReportPDF;