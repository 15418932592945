import React, { useEffect, useState } from "react";
import Conversation from "../components/Conversation";
import ConversationService from "../services/ConversationService";
import styled from '@emotion/styled';
import { w3cwebsocket as W3CWebSocket } from "websocket";
import SupportTimeSlotService from "../services/SupportTimeSlotService";
import specialisationType from "../enums/specialisationType";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { getUser } from "../Utils/Commons";
import {FormLabel} from "@mui/material";
import {useTranslation} from "react-i18next";

const Wrapper = styled.div`
  overflow: auto;
  height: 95vh;
`

// Initiate socket connection
const clientUrl = `${process.env.REACT_APP_WEBSOCKET_URL}?conversationPageRefreshUsers`;
let client = null;

const ConversationListPage = () => {
  const { t } = useTranslation();
  let userSpecSet = false;
  const [conversations, setConversations] = useState([]);
  const [activeTeamMembers, setTeamMembers] = useState([]);
  const [selectedSpecialisation, setSelectedSpecialisation] = useState(() => {
      if (!userSpecSet) {
          userSpecSet = true;
          const user = getUser();

          if (user.plcSupport){
              return specialisationType.PLC;
          } else if (user.scadaSupport){
              return specialisationType.SCADA;
          } else {
              return specialisationType.PLC
          }
      }
  });

  useEffect(() => {
    fetchData();
    connect();
    return () => {
      client && client.close();
    }
  }, []);

  useEffect(() => {
    if (client != null) {
      client.onmessage = (message) => {
        const parsedContent = JSON.parse(message.data);

        if (parsedContent.type === "updateConversations") {
          fetchData();
        }

      }
    }
  }, [client]);

  useEffect(() => {
    fetchData();
  }, [selectedSpecialisation]);

  const fetchData = async () => {
    const conversationService = new ConversationService();
    const conversationsData = await conversationService.getConversations(selectedSpecialisation);

    const supportTimeSlotsService = new SupportTimeSlotService();
    const currentActiveTeamMembers = await supportTimeSlotsService.getCurrentActiveSupportTeamMembers();

    setConversations(conversationsData);
    setTeamMembers(currentActiveTeamMembers);
  };

  function connect() {
    client = new W3CWebSocket(clientUrl);
  }

  function handleRadioOnChange(event, value){
    setSelectedSpecialisation(value);
  }

  return (
      <Wrapper>
        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", margin: "5px 0"}}>
          {activeTeamMembers?.map((teamMember, i) => (
              <div style={{margin: "auto"}} key={i}>{ teamMember.Specialisation }: { teamMember.fullName }</div>
          ))}
        </div>
        <FormControl className={"radio-button-control"}>
          <FormLabel id="radio-buttons-group-label" style={{marginRight: "10px"}}>{ t('filters.specialistRadioButtons')}:</FormLabel>
          <RadioGroup row onChange={handleRadioOnChange} value={selectedSpecialisation} aria-labelledby="radio-buttons-group-label" name="radio-buttons-group">
            <FormControlLabel value={specialisationType.PLC} control={<Radio />} label="PLC" />
            <FormControlLabel value={specialisationType.SCADA} control={<Radio />} label="SCADA" />
          </RadioGroup>
        </FormControl>
        {conversations?.map((c, i) => (
            <Conversation conversation={c} key={i}/>
        ))}
      </Wrapper>
  )
}

export default ConversationListPage;