import React, { useState } from "react";
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import FormRow from "../components/form/FormRow";
import FormSectionRow from "../components/form/FormSectionRow";
import FormWrapper from "../components/form/FormWrapper";
import FormSection from "../components/form/FormSection";
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import ClientService from '../services/ClientService';
import ModalWrapper from "../components/ui/ModalWrapper";
import { getUserColor, getUserSecondaryColor, removeUserSession } from "../Utils/Commons"
import { useHistory } from "react-router-dom";

const Error = styled.div`
  font-size: 12px;
  color: red;
  padding-top: 5px
`

const Text = styled.div`
  text-align: center;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
`

const ResetPaswordPage = () => {
  const [form, setForm] = useState({'currentPassword': '','newPassword': '', 'newPasswordRetype': ''})
  const [formValid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [success, setSuccess] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const handleOnChange = (value, bindOn) => {
    const formData = {...form};
    formData[bindOn] = value;
    setForm(formData);
    setFormValid(true);
  }

  const handleSave = async () => {
    if(!formValidation()) return;

    const clientService = new ClientService();
    const res = await clientService.saveResetPassword(form);

    if(!res.success) {
      setFormValid(false);
      setFormErrors([res.message]);
      return;
    }

    setSuccess(true);

    resetFields();
  }

  const resetFields = () => {
    const updatedForm = {...form};
    updatedForm.currentPassword = null;
    updatedForm.newPassword = null;
    updatedForm.newPasswordRetype = null;

    setForm(updatedForm);
  } 

  const formValidation = () => {
    var valid = true;
    var newErrors = [];

    // Current password defined
    if(!(form.currentPassword.length > 0)) {
      setFormValid(false);
      newErrors.push(t('clientPage.currentPasswordRequired'));
      valid = false;
    }

    // New password required
    if(!form.newPassword || !form.newPasswordRetype) {
      setFormValid(false);
      newErrors.push(t('clientPage.newPasswordRequired'));
      valid = false;
    }

    // New password validation
    if(((!!form.newPassword && form.newPassword.length > 0) || (form.newPasswordRetype && form.newPasswordRetype.length > 0)) 
        && form.newPassword !== form.newPasswordRetype) {
      setFormValid(false);
      newErrors.push(t('clientPage.passwordNotSame'));
      valid = false;
    }

    setFormErrors(newErrors);

    return valid;
  }

  const handleRedirection = () => {
    setSuccess(false);
    removeUserSession();
    history.push('/');
  }

  const successModalBody = () => {
    return (
      <>
        <Text>{t('clientPage.resetPasswordSuccess')}</Text>
        <ButtonRow>
          <Button color={getUserColor()} type="button" action={handleRedirection} textContent="Ok" />
        </ButtonRow>
      </>
    )
  }

  const handleReturn = () => {
    history.goBack();
  }

  return (
    <>
      {success && <ModalWrapper color={getUserSecondaryColor()}>{successModalBody()}</ModalWrapper>}
      <FormWrapper>
        <FormSectionRow>
          <FormRow>
            {t('login.inputs.password')}:
          </FormRow>
          <FormSection>
            <FormRow>
              <Input placeholder={t('clientPage.currentPassword')} type="password" bindOn='currentPassword' onChange={handleOnChange} value={form?.currentPassword} autocomplete="off" />
            </FormRow>
            <FormRow>
              <Input placeholder={t('clientPage.newPassword')} type="password" bindOn='newPassword' onChange={handleOnChange} value={form?.newPassword} autocomplete="off" />
            </FormRow>
            <FormRow>
              <Input placeholder={t('clientPage.newPasswordRetype')} type="password" bindOn='newPasswordRetype' onChange={handleOnChange}  value={form?.newPasswordRetype} autocomplete="off" />
            </FormRow>
          </FormSection>
        </FormSectionRow>
        <Button textContent={t('clientPage.save')} action={handleSave} />
        <Button textContent={t('settings.return')} action={handleReturn} />
        {!formValid && formErrors.map((error, i) => (
          <Error key={i}>{error}</Error>
        ))}
      </FormWrapper>
    </>
  )
}

export default ResetPaswordPage;