import FournisseurServiceTelRepository from "../repositories/FournisseurServiceTelRepository";

class FournisseurServiceTel {

    repo = new FournisseurServiceTelRepository();

    getFournisseurs = async () => {
        return await this.repo.getFournisseurs();
    }

}

export default FournisseurServiceTel