import React, { useState, useEffect } from "react";
import styled from '@emotion/styled';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisV,
  faTasks,
  faCalendarAlt,
  faHeadset,
  faBook,
  faList
} from "@fortawesome/free-solid-svg-icons";
import { useHistory, useParams } from "react-router-dom";

import {
  getUser,
  getUserColor,
  getUserSecondaryColor,
  userHasPermission
} from "../Utils/Commons";
import NoteModal from "./NoteModal";
import CalendarModal from "./CalendarModal";
import NavbarMenuOptions from "./NavbarMenuOptions";

const Wrapper = styled.div`
  padding: 0 10px;
  display: flex;
  height: 40px;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.25);
`

const ClientName = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

const AdminOptions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 20px;
`

const VerticalCenterIcon = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const MenuButton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 10px;
`

const Navbar = ({setRefetchStatus, setNoteVisibility, noteVisibility, rapportVisibility}) => {
  const [conversationId] = useState(useParams().conversationId || 0);
  const [menuVisibility, setMenuVisibility] = useState(false);
  const [calendarVisibility, setCalendarVisibility] = useState(false);
  const [userInformation, setUserInformation] = useState("");
  const history = useHistory();
  const backgroundColor = getUserColor();
  const secondaryColor = getUserSecondaryColor();

  useEffect(() => {
    const user = getUser();

    setUserInformation(`${user?.firstname} ${user?.lastname} (${user?.locationName})`);
  }, []);

  const handleReturnList = () => {
    history.push("/conversations");
  }

  const closeNoteModal = () => {
    setNoteVisibility(false);
  }

  function handleHistoryList() {
    history.push("/historyList")
  }

  const handleTestConnection = () => {
    history.push("/testConnection")
  };

    const navBarOptions = () => {
        return (
            userHasPermission("canMakeCalls") ?
                <ClientName>{userInformation}</ClientName>
                :
                <AdminOptions>
                    <VerticalCenterIcon>
                        {userHasPermission("canAnswerCalls") &&
                            <IconWrapper>
                                <FontAwesomeIcon icon={faList} onClick={handleReturnList}/>
                            </IconWrapper>
                        }
                        {userHasPermission("canSeeHistoCalls") &&
                            <IconWrapper>
                                <FontAwesomeIcon icon={faBook} onClick={handleHistoryList}/>
                            </IconWrapper>
                        }
                        {userHasPermission("canSeeTestConnection") &&
                            <IconWrapper>
                                <FontAwesomeIcon icon={faTasks} onClick={handleTestConnection}/>
                            </IconWrapper>
                        }
                    </VerticalCenterIcon>
                    <VerticalCenterIcon>
                        {userHasPermission("canSeeConfig") &&
                            <IconWrapper>
                                <FontAwesomeIcon icon={faHeadset} onClick={() => history.push("/answerers")}/>
                            </IconWrapper>
                        }
                        {userHasPermission("canSeeCalendar") &&
                            <IconWrapper>
                                <FontAwesomeIcon icon={faCalendarAlt} onClick={() => setCalendarVisibility(!calendarVisibility)}/>
                            </IconWrapper>
                        }
                    </VerticalCenterIcon>
                </AdminOptions>
        )
    }

  return (
    <Wrapper style={{backgroundColor: backgroundColor }}>
      {navBarOptions()}
      <MenuButton onClick={() => setMenuVisibility(!menuVisibility)}><FontAwesomeIcon icon={faEllipsisV} /></MenuButton>
      {calendarVisibility &&
        <CalendarModal setCalendarVisibility={setCalendarVisibility} backgroundColor={secondaryColor} />}
      {menuVisibility && 
        <NavbarMenuOptions backgroundColor={secondaryColor} setMenuVisibility={setMenuVisibility} setRefetchStatus={setRefetchStatus} setNoteVisibility={setNoteVisibility} rapportVisibility={rapportVisibility} />}
      {noteVisibility && 
        <NoteModal color={secondaryColor} closeNoteModal={closeNoteModal} conversationId={conversationId} />
      }
    </Wrapper >
  )
}

export default Navbar