import { useState } from 'react';
import specialisationType from "../enums/specialisationType"
import SupportTimeSlotService from "../services/SupportTimeSlotService";
import moment from "moment";

const UseFetchCalendarRanges = async (selectedSpecialisation = specialisationType.PLC) => {
  const defaultRange = {
    startDate : null, 
    endDate : new Date(""),
    key : 'selection'
  };
  var ranges = [];

  const supportTimeSlotService = new SupportTimeSlotService();
  const supportTimeSlotsData = await supportTimeSlotService.getTimeSlots(selectedSpecialisation);

  // Set ranges
  const newRanges = [defaultRange];
  supportTimeSlotsData.supportTimeSlots.map(sts => {
    const newRange = {
      id: sts.id,
      startDate: moment(sts.startTime).toDate(),
      endDate: moment(sts.endTime).toDate(),
      color: sts.color,
      userId: sts.userId,
      exception: sts.exception,
      key: 'selection'
    }
    newRanges.push(newRange);
  });

  ranges = newRanges;

  return {ranges, supportTimeSlotsData};
}  

export default UseFetchCalendarRanges;