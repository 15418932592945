import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';

const AccordionWrapper = ({title, children}) => {
  return (
    <Accordion allowZeroExpanded preExpanded={['accordionUuid']}>
      <AccordionItem uuid="accordionUuid">
        <AccordionItemHeading>
          <AccordionItemButton>
            {title}
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          {children}
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default AccordionWrapper;