import styled from '@emotion/styled';
import Button from '../components/ui/Button';

const Text = styled.div`
  text-align: center;
`

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-top: 20px;
`

const ModalBody = ({text, button1Color, button1Action, button1Text, button2Color, button2Action, button2Text}) => {
  return (
    <>
      <Text>{text}</Text>
      <ButtonRow>
        {button1Text && <Button action={() => button1Action()} textContent={button1Text} />}
        {button2Text && <Button action={() => button2Action()} textContent={button2Text} />}
      </ButtonRow>
    </>
  )
}

export default ModalBody;