import styled from '@emotion/styled';

const Wrapper = styled.div(
  {
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0px 5px'
  }, props => ({
    backgroundColor: props.color
  })
)

const UserColor = ({color}) => {
  return (
    <Wrapper color={color} />
  )
}

export default UserColor;