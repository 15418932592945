import {Alert} from "@mui/lab";
import Snackbar from "@mui/material/Snackbar";

const Toast = ({toastState, setToastState}) => {
    const { vertical, horizontal, open, message, severity } = toastState;

    const handleClose = () => {
        setToastState({...toastState, open: false});
    };

    return (
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{vertical, horizontal}} key={vertical + horizontal}>
            <Alert
                onClose={handleClose}
                severity={severity}
                variant="filled"
                sx={{ width: '100%' }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
}

export default Toast;