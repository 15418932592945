import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import {useTranslation} from "react-i18next";
import Input from '../components/ui/Input';
import Select from '../components/ui/Select';
import ClientService from '../services/ClientService';
import FactoryService from '../services/FactoryService';
import Button from '../components/ui/Button';
import FormRow from "../components/form/FormRow";
import TimeZoneSelection from "../components/TimeZoneSelection";
import ColorPicker from "../components/ColorPicker";
import FormWrapper from "../components/form/FormWrapper";
import FormSectionRow from "../components/form/FormSectionRow";
import FormSection from "../components/form/FormSection";
import {Checkbox, FormControlLabel} from "@mui/material";
import { isIOS } from "react-device-detect";
import FournisseurServiceTel from "../services/FournisseurServiceTel";
import RolesService from "../services/RolesService";

const Error = styled.div`
  font-size: 12px;
  color: red;
  padding-top: 5px
`

const Success = styled.div`
  font-size: 12px;
  color: green;
  padding-top: 5px
`

const CheckBoxStyles = {
    default: {
        justifyContent: "center",
        border: "1px solid #999",
        margin: "3px",
        borderRadius: "5px",
        width: "98%",
        height: "87.1%"
    },
    iOS: {
        justifyContent: "center",
        border: "1px solid #999",
        margin: "3px",
        borderRadius: "5px",
        width: "95%",
        height: "9.7%",
        minHeight: "50px"
    }
};

const formFields = ["firstname", "lastname", "email", "phoneNumber", "locationName", "timeZoneOffset", "language", "username"];

const ClientPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [formValid, setFormValid] = useState(true);
  const [formErrors, setFormErrors] = useState([]);
  const [formSuccess, setFormSuccess] = useState();
  const [clientId, setClientId] = useState(useParams().userId || 0);
  const [factories, setFactories] = useState([]);
  const [fournisseursService, setFournisseursService] = useState([]);
  const [roles, setRoles] = useState([]);
  const [client, setClient] = useState({
      id: 0,
      language: 'fr',
      timeZoneOffset: '-05:00',
      isActive: true,
      factoryId: undefined,
      roles: []
  });
  const languages = [
    {
      'id': 'fr',
      'name': 'fr'
    },
    {
      'id': 'en',
      'name': 'en'
    },
  ];

  useEffect(() => {
    if(clientId !== 0) {
      fetchClient();
    }
    fetchFactories();
    fetchRoles();
    fetchFournisseurs();
  }, []);

  const fetchClient = async () => {
    const clientService = new ClientService();
    const clientData = await clientService.getClient(clientId);

    setClient(clientData);
  }

  const fetchFactories = async () => {
    const factoryService = new FactoryService();
    const factoriesData = await factoryService.getFactories();

    setFactories(factoriesData);
  }

  const fetchRoles = async () => {
    const rolesService = new RolesService();
    const rolesData = await rolesService.getRoles();

    setRoles(rolesData);
  }

  const fetchFournisseurs = async () => {
    const fournisseurServiceTel = new FournisseurServiceTel();
    let fournisseursData = await fournisseurServiceTel.getFournisseurs();

    fournisseursData.forEach((fournisseur) => {
      fournisseur.name = fournisseur.companyName;
      fournisseur.value = fournisseur.id;
    });

    setFournisseursService(fournisseursData);
  }

  const handleSave = async () => {
    if(!formValidation()) return;

    const clientService = new ClientService();
    const res = await clientService.saveClient(client);

    if(!res.success) {
      setFormValid(false);
      setFormErrors([res.message]);

      if(res.error === "USERNAME_EXISTS"){
        const clientData = {...client};
        clientData["username"] = "";
        setClient(clientData);
      }

      return;
    }

    if(clientId === 0) {
        if(res.clientId > 0){
            const modifiedClient = {...client};
            modifiedClient.id = res.clientId;
            await saveRolesNewUser(modifiedClient);
        }

      history.replace('user/'+res.clientId);
      setClientId(res.clientId);

      setTimeout(() => {
        const updatedClient = {...client};
        updatedClient.id = res.clientId;
        setClient(updatedClient);
      }, 1000);
    }

    setFormSuccess("Saved!");
    setTimeout(() => {
      setFormSuccess(null);
    }, 3000);

    resetFields();
  }

  const resetFields = () => {
    const updatedClient = {...client};
    updatedClient.newPassword = null;
    updatedClient.newPasswordRetype = null;

    setClient(updatedClient);
  }

  const formValidation = () => {
    var valid = true;
    var newErrors = [];

    // Fields check
    formFields.forEach(field => {
      if(!client[field] || client[field] === undefined) {
        setFormValid(false);
        newErrors.push(field+" is missing");
        valid = false;
      }
    })

      if(client.roles.length === 0) {
          setFormValid(false);
          newErrors.push("No roles are selected");
          valid = false;
      }

    // Color check only for respondent and admins
    if((!client.color) && !clientHasRole(client, "Demandeur")) {
      setFormValid(false);
      newErrors.push("color is missing");
      valid = false;
    }

    // Password validation
    if(((!!client.newPassword && client.newPassword.length > 0) || (client.newPasswordRetype && client.newPasswordRetype.length > 0)) 
        && client.newPassword !== client.newPasswordRetype) {
      setFormValid(false);
      newErrors.push("Passwords are not the same");
      valid = false;
    }

    setFormErrors(newErrors);

    return valid;
  }

  const handleOnChange = (value, bindOn) => {
    const clientData = {...client};
    clientData[bindOn] = value;

    if(!isFactoryActive(clientData)){
      clientData.isActive = false;
    }

    setClient(clientData);
    setFormValid(true);
  }
  const handleOnChangeChecked = (event) => {
    const clientData = {...client};
    clientData.isActive = event.target.checked;
    setClient(clientData);
    setFormValid(true);
  }

  const isFactoryActive = (client) => {
      if (client?.factoryId === undefined) {
          return true;
      } else {
          return factories.filter(factory => factory.id === client.factoryId)[0]?.isActive;
      }
  }

  const handleReturn = () => {
    history.push('/settings');
  }

    const clientHasRole = (client, roleName) => {
        return client?.roles?.find(role => role.name === roleName) !== undefined;
    };

    const getRoleByName = (roleName) => {
        return roles.find(role => role.name === roleName);
    };

    const handleToggleRole = (client, roleName) => {
        const rolesService = new RolesService();
        const role = getRoleByName(roleName);
        const clientData = {...client}

        if(clientHasRole(client, roleName)){
            if(clientId <= 0){
                clientData.roles.splice(clientData.roles.indexOf(role), 1);
                setClient(clientData)
            } else {
                rolesService.deleteUserRoles(client.id, role.id).then(fetchClient());
            }
        } else {
            if(clientId <= 0){
                clientData.roles.push(role);
                setClient(clientData)
            } else {
                rolesService.addUserRoles(client.id, role.id).then(fetchClient());
            }
        }
    };

    const verifyRoles = (client, roleName) => {
        let hasRole = false;

        switch (roleName){
            case "Demandeur":
                hasRole = (clientHasRole(client, "Répondant") || clientHasRole(client, "Directeur") || clientHasRole(client, "Admin"));
                break;
            case "Répondant":
            case "Directeur":
            case "Admin":
                hasRole = clientHasRole(client, "Demandeur");
                break;
        }

        return hasRole;
    };

    const saveRolesNewUser = async (client) => {
        const rolesService = new RolesService();
        for (const role of client.roles) {
            await rolesService.addUserRoles(client.id, role.id);
        }
    };

  return (
    <FormWrapper>
      <FormSectionRow>
        <FormRow>
          {t('clientPage.accountInfo')}:
        </FormRow>
        <FormSection>
          <FormRow>
            <Input placeholder={t('clientPage.firstname')} value={client?.firstname} bindOn='firstname' onChange={handleOnChange}/>
            <Input placeholder={t('clientPage.lastname')} value={client?.lastname} bindOn='lastname' onChange={handleOnChange}/>
          </FormRow>
          <FormRow>
            <Input placeholder={t('clientPage.email')}  value={client?.email} bindOn='email' onChange={handleOnChange} />
          </FormRow>
          <FormRow>
            <Input type="phoneNumber" placeholder={t('clientPage.phone')}  value={client?.phoneNumber} bindOn='phoneNumber' onChange={handleOnChange} />
            <Input placeholder={t('clientPage.extension')}  value={client?.extension} bindOn='extension' onChange={handleOnChange} />
          </FormRow>
          {client?.factoryId === 1 &&
              <FormRow>
                <Select placeholder={t('clientPage.cellServiceProvider')} value={client?.fournisseurService} data={fournisseursService} bindOn='fournisseurService' onChange={handleOnChange} />
              </FormRow>
          }
        </FormSection>
      </FormSectionRow>
      <FormSectionRow>
        <FormRow>
          {t('clientPage.location')}:
        </FormRow>
        <FormSection>
          <FormRow>
            <Input placeholder={t('clientPage.location')}  value={client?.locationName} bindOn='locationName' onChange={handleOnChange} />
            <Select placeholder={t('clientPage.factoryId')} value={client?.factoryId} data={factories} bindOn='factoryId' onChange={handleOnChange} verifyActive={true} />
          </FormRow>
          <FormRow>
            <TimeZoneSelection placeholder={t('clientPage.timezone')}  value={client?.timeZoneOffset} bindOn='timeZoneOffset' onChange={handleOnChange} />
            <Select placeholder={t('clientPage.language')} value={client?.language} data={languages} bindOn='language' onChange={handleOnChange} />
          </FormRow>
        </FormSection>
      </FormSectionRow>
      <FormSectionRow>
        <FormRow>
          {t('clientPage.other')}:
        </FormRow>
        <FormSection>
          <FormRow>
            <ColorPicker value={client?.color}  bindOn='color' onChange={handleOnChange} />
          </FormRow>
        </FormSection>
      </FormSectionRow>
      <FormSectionRow>
        <FormRow>
          {t('clientPage.loginInfo')}:
        </FormRow>
        <FormSection>
          <FormRow>
            <Input placeholder={t('clientPage.username')} type="text" bindOn='username' onChange={handleOnChange} value={client?.username} autocomplete="new-text" autoCompleteOff required />
            <FormControlLabel label={t('clientPage.userActive')} control={
                <Checkbox checked={!!client?.isActive} onChange={handleOnChangeChecked} disabled={!isFactoryActive(client)}/>
            } labelPlacement="start" style={ isIOS ? CheckBoxStyles.iOS : CheckBoxStyles.default }/>
          </FormRow>
          <FormRow>
            <Input placeholder={t('clientPage.newPassword')} type="password" bindOn='newPassword' onChange={handleOnChange} value={client?.newPassword} autocomplete="new-password" autoCompleteOff />
            <Input placeholder={t('clientPage.newPasswordRetype')} type="password" bindOn='newPasswordRetype' onChange={handleOnChange}  value={client?.newPasswordRetype} autocomplete="new-password" autoCompleteOff />
          </FormRow>
        </FormSection>
      </FormSectionRow>
      <FormSectionRow>
        <FormRow>
          Rôles:
        </FormRow>
        <FormSection>
          <FormRow>
              {roles.map((role) => (
                  <FormControlLabel label={role.name} control={<Checkbox checked={!!clientHasRole(client, role.name)}
                      onChange={() => handleToggleRole(client, role.name)} disabled={!isFactoryActive(client)}/>}
                      labelPlacement="start" style={ isIOS ? CheckBoxStyles.iOS : CheckBoxStyles.default } disabled={verifyRoles(client, role.name)}/>
              ))}
          </FormRow>
        </FormSection>
      </FormSectionRow>
      <Button textContent={t('clientPage.save')} action={handleSave} />
      <Button textContent={t('settings.return')} action={handleReturn} />
      {!formValid && formErrors.map((error, i) => (
        <Error key={i}>{error}</Error>
      ))}
      {formSuccess && <Success>{formSuccess}</Success>}
    </FormWrapper>
  )
}

export default ClientPage;