import styled from '@emotion/styled';

const WrapperSocketStatus = styled.div`
  padding: 5px 25px;
  display: flex;
  height: 10px;
  flex-direction: row;
  justify-content: flex-end;
`

const SocketStatusIndicator = styled.div(
  {
    height: '7px',
    width: '7px',
    borderRadius: '50%',
    zIndex: 8
  }, props => ({ background: props.connected ? 'green' : 'red' })
)

const WebSockeStatusIndicator = ({socketStatus}) => {
  return (
      <WrapperSocketStatus>
        <SocketStatusIndicator connected={socketStatus === WebSocket.OPEN} />
      </WrapperSocketStatus>
    )
}

export default WebSockeStatusIndicator;