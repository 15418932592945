import styled from '@emotion/styled';
import { getUserColor, getUserTextColor } from '../../Utils/Commons';
import Button from '@mui/material/Button';
import {isEdge, isEdgeChromium, isIE} from "react-device-detect";

const ButtonStyled = styled(Button)`
  background-color: ${props => props.buttoncolor};
  border-color: ${props => props.buttoncolor};
  width: ${props => props.squared ? '30px' : ''};
  margin: 3px;
  color: ${props => props.textcolor};
  &:hover {
    background-color: ${props => props.buttoncolor};
    border-color: ${props => props.buttoncolor};
    filter: brightness(0.95);
    color: ${props => props.textcolor};
  }
`

const DefaultButton = ({textContent, action, color, squared}) => {
  const textColor = getUserTextColor();

  return (
    <ButtonStyled variant="contained" buttoncolor={color ?? getUserColor()} squared={squared} onClick={() => action()}
                  textcolor={textColor} disabled={isIE || isEdge || isEdgeChromium}
    >
      {textContent}
    </ButtonStyled>
  )
}

export default DefaultButton;