import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClipboard, faFileSignature} from "@fortawesome/free-solid-svg-icons";

const DemandeurBanner = ({user, secondaryColor, noteId, rapportId}) => {
    return (
        <div style={{padding: "10px", borderBottom: `2px solid ${secondaryColor}`}}>
            <div style={{display: "flex", flexDirection: "row", fontWeight: "500"}}>
                <div>{`${user.firstname} ${user.lastname}`}</div>
                <div style={{flex: 1, textAlign: "end"}}>{user.phoneNumber}</div>
            </div>
            <div style={{display: "flex", flexDirection: "row", fontWeight: "500"}}>
                <div style={{color: "#B0B0B0"}}>{user.email}</div>
                <div style={{flex: 1, textAlign: "end"}}>
                {noteId > 0 && <FontAwesomeIcon icon={faClipboard} style={{margin: "0 5px"}}></FontAwesomeIcon>}
                {rapportId > 0 && <FontAwesomeIcon icon={faFileSignature}></FontAwesomeIcon>}
                </div>
            </div>
            <div style={{display: "flex", flexDirection: "row", fontWeight: "500"}}>
                <div>Dir: {user.factory.directorName}</div>
            </div>
        </div>
    )
}

export default DemandeurBanner;