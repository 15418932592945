import styled from '@emotion/styled';
import ModalBackdrop from '../ModalBackdrop';

const Wrapper = styled.div(
  {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    margin: "2px",
    borderRadius: "3px",
    minWidth: "250px",
    padding: "10px",
    zIndex: "9",
    boxShadow: "rgba(0, 0, 0, 0.25) 3.95px 3.95px 5px"
  }, props => ({backgroundColor: props.color})
)

const ModalWrapper = ({color, children}) => {
  return (
    <>
      <ModalBackdrop />
      <Wrapper color={color}>{children}</Wrapper>
    </>
  )
}

export default ModalWrapper;