import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import ConversationStatusService from "../../services/conversationStatusService";
import ConversationService from "../../services/ConversationService";
import {useTranslation} from "react-i18next";
import {getUser} from "../../Utils/Commons";
import RapportInterventionService from "../../services/RapportInterventionService";
import Select from "../ui/Select";
import statusEnum from "../../enums/statusEnum";
import moment from "moment";
import styled from "@emotion/styled";
import {pdf} from "@react-pdf/renderer";
import InterventionReportPDF from "../InterventionReportPDF";
import ClientService from "../../services/ClientService";

const RapportIntervention = () => {
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const { t } = useTranslation();
    const history = useHistory();
    const [conversationId] = useState(useParams().conversationId || 0);
    const [conversation, setConversation] = useState(null);
    const [chatMessages, setChatMessages] = useState([]);
    const [factoryClient, setFactoryClient] = useState([]);
    const [technicien, setTechnicien] = useState([]);

    const [q1Value, setQ1Value] = useState("");
    const [q2Value, setQ2Value] = useState("");
    const [q3Value, setQ3Value] = useState("");
    const [q4Value, setQ4Value] = useState("");
    const [q5Value, setQ5Value] = useState("");
    const [codeValue, setCodeValue] = useState(0);
    const [nextReportNumber, setNextReportNumber] = useState(0);

    const questions = [
        {
            "index": 0,
            "description": "rapportIntervention.questions.q1",
            "value": q1Value,
            "setValue": setQ1Value
        },
        {
            "index": 1,
            "description": "rapportIntervention.questions.q2",
            "value": q2Value,
            "setValue": setQ2Value
        },
        {
            "index": 2,
            "description": "rapportIntervention.questions.q3",
            "value": q3Value,
            "setValue": setQ3Value
        },
        {
            "index": 3,
            "description": "rapportIntervention.questions.q4",
            "value": q4Value,
            "setValue": setQ4Value
        },
        {
            "index": 4,
            "description": "rapportIntervention.questions.q5",
            "value": q5Value,
            "setValue": setQ5Value
        }
    ];

    const codes = [
        {id: 1, name: `${t(`rapportIntervention.codes.critical.severity`)}: ${t(`rapportIntervention.codes.critical.description`)}`, value: "critical"},
        {id: 2, name: `${t(`rapportIntervention.codes.high.severity`)}: ${t(`rapportIntervention.codes.high.description`)}`, value: "high"},
        {id: 3, name: `${t(`rapportIntervention.codes.medium.severity`)}: ${t(`rapportIntervention.codes.medium.description`)}`, value: "medium"},
        {id: 4, name: `${t(`rapportIntervention.codes.low.severity`)}: ${t(`rapportIntervention.codes.low.description`)}`, value: "low"}
    ];

    const Header = styled.div({
        margin: "auto",
        maxWidth: windowSize[0] > 700 ? "40%": "90%",
        minWidth: "400px",
        display: "flex",
        justifyContent: "space-between"
    });

    useEffect(() => {
        if (conversationId !== 0) {
            fetchData();
        }
    }, [conversationId]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };
        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const fetchData = async() => {
        // Get the messages history for the conversation and update the messages state.
        const conversationService = new ConversationService();
        var conversationData = await conversationService.getConversation(conversationId);

        if(conversationData) {
            var {messages: messagesData, ...conversationData} = conversationData;

            setConversation(conversationData);
            setChatMessages(messagesData);

            setFactoryClient(conversationData.user.factory);
        }

        const rapportInterventionService = new RapportInterventionService();
        const nextReportNumberData = await rapportInterventionService.getNextReportNumber(conversationId);

        if(nextReportNumberData){
            setNextReportNumber(nextReportNumberData);
        }

        await fetchConversationStatus();
        await fetchTechnicienInfo();
    }

    const fetchConversationStatus = async() => {
        const conversationStatusService = new ConversationStatusService();
        const status = await conversationStatusService.getConversationStatus(conversationId);

        const updatedConversation = {...conversation};
        updatedConversation.status = status;
        setConversation(updatedConversation);
    }

    const fetchTechnicienInfo = async () => {
        const clientService = new ClientService();
        const technicienData = await clientService.getClient(getUser().id);

        if (technicienData) {
            setTechnicien(technicienData);
        }
    };

    const handleForm = () => {
        questions.forEach((q, index) => {
            if (q.value === "" || q.value.length <= 0) {
                document.getElementById("ta-" + index).style.border = "5px solid red";
                return false;
            }
        });

        if (codeValue === 0) {
            document.getElementById("codeInput").style.border = "5px solid red";
            return false;
        }

        const rapport = {
            "conversationId": conversationId,
            "technicienId": technicien.id,
            "questions": questions,
            "code": codes.find(c => c.id === codeValue).value,
            "reportNumber": nextReportNumber
        }

        const rapportInterventionService = new RapportInterventionService();
        rapportInterventionService.saveRapport(rapport).then(async r => {
            if (r.success) {
                const blob = await pdf(
                    <InterventionReportPDF t={t} rapportIntervention={rapport}
                     questions={rapport.questions} conversation={conversation} chatMessages={chatMessages}
                     factoryClient={factoryClient} technicien={technicien}
                    />
                ).toBlob();

                await rapportInterventionService.sendRapportEmail(blob, getCodeProjet(), conversationId);

                history.push('/conversations')
            }
        });
    };

    const handleOnChange = (event, question) => {
        event.target.style.border = "";
        question.setValue(event.target.value);
    };

    const handleOnChangeCode = (value) => {
        document.getElementById("codeInput").style.border = "";
        setCodeValue(value);
    }
    const getStatusHour = (statusCode) => {
        const date = conversation?.status.find(s => s.status === statusCode)?.createdAt ?? null
        return date !== null ? moment(date).format("YYYY-MM-DD HH:mm") : "";
    };

    const getCodeProjet = () => {
        let reportNumber = nextReportNumber;
        if (reportNumber < 10){ reportNumber = `0${reportNumber}`; }

        return `${factoryClient.codeProjet}-RI-${reportNumber}a`;
    };

    return (
        <div>
            <Header>
                <h3>&nbsp;</h3>
                <h3>{t('rapportIntervention.nom')}</h3>
                <h3 style={{color: "red"}}>{getCodeProjet()}</h3>
            </Header>
            <Header>
                <table>
                    <tbody>
                        <tr>
                            <td>{t('rapportIntervention.header.caller')}:</td>
                            <td>{chatMessages[0]?.user.name}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{chatMessages[0]?.user.factoryName}</td>
                        </tr>
                        <tr>
                            <td>{t('rapportIntervention.header.call')}:</td>
                            <td>{getStatusHour(statusEnum.CREATED)}</td>
                        </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                        <tr>
                            <td>{t('rapportIntervention.header.answer')}:</td>
                            <td>{getStatusHour(statusEnum.RESPONDED)}</td>
                        </tr>
                        <tr>
                            <td>{t('rapportIntervention.header.resolved')}:</td>
                            <td>{getStatusHour(statusEnum.RESOLVED)}</td>
                        </tr>
                        <tr>
                            <td>{t('rapportIntervention.header.report')}:</td>
                            <td>{moment().format("YYYY-MM-DD HH:mm")}</td>
                        </tr>
                    </tbody>
                </table>
            </Header>

            <form style={{margin: "30px 0"}}>
                {questions?.map((question, index) => (
                    <div style={{margin: "auto", maxWidth: windowSize[0] > 700 ? "40%": "90%", height: index === 0 ? "330px": "260px", minWidth: "400px"}} key={index}>
                        {index === 0 &&
                            <div id={"codeInput"} style={{borderRadius: 5}}>
                                <Select placeholder={"Code"} value={codeValue} data={codes} bindOn={setCodeValue} onChange={handleOnChangeCode}/>
                            </div>
                        }
                        <p style={{textAlign: "left"}}>{t(question.description)}</p>
                        <textarea style={{width: "100%", height: "150px", resize: "none", borderRadius: 5}}
                                  name={"ta-"+index} value={question.value} id={"ta-"+index}
                                  onChange={(e) => handleOnChange(e, question)} required={true}
                        ></textarea>
                    </div>
                ))}
                <div style={{margin: "auto", maxWidth: windowSize[0] > 700 ? "40%": "90%", minWidth: "400px"}}>
                    <input type={"button"} value={t('chat.newForm.send')} onClick={handleForm} />
                </div>
            </form>
        </div>
    );
}

export default RapportIntervention;