import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import { useHistory } from "react-router-dom";
import styled from '@emotion/styled';
import { getUser } from "../Utils/Commons"
import ConfirmationChatFormModal from './ConfirmationChatFormModal';
import ConversationService from '../services/ConversationService';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import { messageMaxLength } from '../global_variables';
import ResolvedCallForm from "./ResolvedCallForm";
import Checkbox from "./ui/Checkbox";
import FactoryService from "../services/FactoryService";
import moment from "moment";
import SupportHebdoModal from "./SupportHebdoModal";
import i18next from "i18next";

const Center = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Wrapper = styled.div`
  @media (max-width: 1007px) {
    width: 80%;
  }
  width: 40%;
  position: relative;
  top: 5vh;
  height: 50vh;
  text-align: center;
`

const Title = styled.div`
  font-size: 18px;
  font-weight: 600;
`

const Error = styled.div`
  font-size: 12px;
  color: red;
  padding-top: 5px
`

const WarningText = styled.div`
  color: #f53737;
`

const NewChatForm = ({setConversationId, handleNotifyNewConversation, lastConversationData, setLastConversationData}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const user = getUser();
  const [factory, setFactory] = useState([]);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [supportHebdoModalVisible, setSupportHebdoModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [newConversationId, setNewConversationId] = useState(null);
  let lastInputKey = "";
  const inputScrollRef = useRef(null);
  const [agreeCosts, setAgreeCosts] = useState(false);

  useEffect(() => {
    // Set defaut values
    setName(user?.firstname.concat(" ", user?.lastname));
    setPhone(user?.phoneNumber);

    if(factory.length === 0){
      getFactoryData();
    }
  }, [])

  const getFactoryData = async () => {
    const factoryService = new FactoryService();
    const data = await factoryService.getFactory(user.factoryId);
    setFactory(data);
  }

  const handleSubmitForm = () => {
    // validation fields
    if(!name || !phone || !message) {
      alert(t("chat.newForm.errorRequiredFields"));
      return false;
    }

    return true;
  }

  // Create and notify the technician for the new conversation
  const handleConfirmation = async () => {
    const utcDate = moment().utcOffset(user.timeZoneOffset).format();
    const soluDate = moment(utcDate).tz("America/Montreal");
    let isSupportHebdo = false;

    if(error || !agreeCosts) return;
    if(!handleSubmitForm()) return;
    const formattedMessage = formatMessage();

    if(factory.supportHebdo && ((soluDate.hours() >= 17 && soluDate.minutes() >= 0 && soluDate.seconds() >= 0) || soluDate.hours() < 8)){
      setSupportHebdoModalVisible(true);
      isSupportHebdo = true;
    } else {
      setConfirmationModalVisible(true);
    }

    // Create conversation
    const conversationService = new ConversationService();
    const conversationId = await conversationService.createNewConversation(user.id, formattedMessage, name, phone, isSupportHebdo);
    setNewConversationId(conversationId)

    // Send notification for conversation
    !!conversationId && handleNotifyNewConversation();

  }

  // Set status and redirect to conversation
  const handleRedirection = () => {
    setConfirmationModalVisible(false);
    setSupportHebdoModalVisible(false);

    if(!!newConversationId) {
      history.push('chat/' + newConversationId);
      setConversationId(newConversationId);
    }
  }

  const formatMessage = () => {
    return name.concat(", ", phone, ", ", message);
  }

  const handleOnChange = (value, bindOn) => {
    scrollToBottom();
    if(formatMessage().length >= messageMaxLength && lastInputKey !== "Backspace") {
      return false;
    }

    bindOn(value.substring(0, (messageMaxLength - name.concat(", ", phone, ", ").length)));
    setError(null)
  }

  const handleOnKeyDown = (event) => {
    lastInputKey = event.key;
    // Prevent the user from typing if the limit has been reached but allow to remove with backspace.
    if(formatMessage().length >= messageMaxLength && event.key !== "Backspace"){
      event.preventDefault();
      return false;
    }
  }

  const scrollToBottom = () => {
    inputScrollRef.current.scrollIntoView();
  }

  if(lastConversationData !== null) {
    return (<Center><ResolvedCallForm from={lastConversationData.techName} date={lastConversationData.createdAt} setLastConversationData={setLastConversationData}/></Center>)
  }

  return (
    <Center>
      {supportHebdoModalVisible && <SupportHebdoModal handleRedirection={() => handleRedirection()}/>}
      {confirmationModalVisible && <ConfirmationChatFormModal handleRedirection={() => handleRedirection()} />}
      <Wrapper>
        <Title style={{marginBottom: "5px", fontSize: "18px"}}>{t("chat.newForm.enterInfosMsg")}</Title>
        <div>
          <Input placeholder={t("chat.newForm.name")}  value={name} bindOn={setName} onKeyDown={handleOnKeyDown} onChange={handleOnChange} required={true}/>
          <Input placeholder={t("chat.newForm.phone")}  value={phone} bindOn={setPhone} onKeyDown={handleOnKeyDown} onChange={handleOnChange} required={true}/>
          <Input placeholder={t("chat.newForm.message")} type={"text"} value={message} bindOn={setMessage} onKeyDown={handleOnKeyDown} onChange={handleOnChange} multiline rows={8} required={true}/>
          <p ref={inputScrollRef}>{t("chat.charLimit")}: { messageMaxLength - formatMessage().length }</p>
        </div>
        {i18next.language === 'fr' &&
            <WarningText >{t("chat.newForm.warningFeesMsg")} <span style={{fontWeight: "bold"}}>{t("chat.newForm.welcomeEmergency")}</span>.</WarningText>
        }
        {i18next.language === 'en' &&
            <WarningText >{t("chat.newForm.warningFeesMsg")} <span style={{fontWeight: "bold"}}>{t("chat.newForm.welcomeEmergency")}</span> call.</WarningText>
        }
        <Button textContent={t("chat.newForm.send")} action={() => handleConfirmation()} color={!agreeCosts ? 'grey' : null}/>
        <Checkbox label={t("chat.newForm.acceptFees")} value={agreeCosts} onChange={() => setAgreeCosts(!agreeCosts)}></Checkbox>
        <Error>{error}</Error>
      </Wrapper>
    </Center>
  )
}

export default NewChatForm;