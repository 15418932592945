import styled from '@emotion/styled';

const SectionRow = styled.div`
  margin-top: 25px;
  @media (min-width: 600px) {
    justify-content: space-around;
    display: flex;
    flex-direction: row;
  }
`

const FormSectionRow = ({children}) => {
  return (
    <SectionRow>
      {children}
    </SectionRow>
  )
}

export default FormSectionRow;