import React, { useEffect, useRef } from "react";
import ChatBubble from "./ChatBubble";
import styled from '@emotion/styled';

const Wrapper = styled.div`
  margin: 0px 10px;
  height: 30%;
  min-height: 300px;
  color: black;
  padding-top: 20px;
`

const ChatWrapper = ({ messages, userId, secondaryColor, lastMessageSeen }) => {
  const messagesEndRef = useRef(null)

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: 'end'  });
  }

  return (
    <Wrapper>
      {messages.map((message, i) => (
        <ChatBubble key={i} message={message} userId={userId} secondaryColor={secondaryColor} lastMessageSeen={lastMessageSeen} isLast={messages.length - 1 === i}/>
      ))}
      <div ref={messagesEndRef} />
    </Wrapper>
  )
}

export default ChatWrapper;