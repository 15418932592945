import axios from 'axios';
import {getToken} from '../Utils/Commons';

class NoteRepository {
    addNote = async (noteMessage, conversationId) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Note.php`,
            {jwt: token, noteMessage: noteMessage, conversationId: conversationId, mode: "addNote"},
            {headers}
        )
            .then(response => {

            }).catch(error => {
                // Handle error back to the service > login page
            });
    }

    getNote = async (conversationId) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        let data = {};
        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Note.php`,
            {jwt: token, conversationId: conversationId, mode: "getNote"},
            {headers}
        ).then(response => {
            data = response.data;
        }).catch(error => {
                // console.log(error);
        });

        return data;
    }

    updateNote = async (noteInfo) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Note.php`,
            {jwt: token, noteMessage: noteInfo.noteMessage, conversationId: noteInfo.conversationId, mode: "updateNote"},
            {headers}
        ).then(response => {

        }).catch(error => {
            // console.log(error);
        });
    }

    deleteNote = async (conversationId) => {
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        const token = getToken();
        await axios.post(
            `${process.env.REACT_APP_API_URL}api/Note.php`,
            {jwt: token, conversationId: conversationId, mode: "deleteNote"},
            {headers}
        ).then(response => {

        }).catch(error => {
            // console.log(error);
        });
    }
}

export default NoteRepository;